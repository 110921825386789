import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';

import { deleteTerminatedOrgs } from '@State/sysadmin-actions';
import ButtonModalForm from '@Components/dialogs/button-modal-form';
import HookFormInput from '@Components/inputs/hook-form-input';
import FormButtonsFooter from '@Components/ui/form-buttons-footer';
import DialogAlert from '@Components/dialogs/dialog-alert';

const DeleteLocations = () => {
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const methods = useForm({
    defaultValues: {
      terminatedBefore: moment().add(-3, 'year').startOf('month')
    }
  });

  const onDeleteLocations = ({ terminatedBefore }) => {
    return dispatch(deleteTerminatedOrgs(terminatedBefore))
      .then(() => setSuccess(terminatedBefore))
      .catch(err => setError(err))
      .finally(() => methods.reset());
  };

  return (
    <>
      {success && (
        <DialogAlert
          success
          title=""
          icon="fa fa-check-circle"
          text={`Konton avslutade innan ${moment(success).format('LL')} har raderats`}
          onClose={() => setSuccess(null)}
          closeButtonText="Stäng"
        />
      )}
      {error && (
        <DialogAlert
          warning
          title=""
          icon="fa fa-exclamation-triangle"
          text="Något gick fel, se loggen för mer information"
          onClose={() => setError(null)}
          closeButtonText="Stäng"
        />
      )}
      <ButtonModalForm
        onSubmit={onDeleteLocations}
        btnText="Radera avslutade konton"
      >
        {({ onClose, onSubmit, loading }) => (
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <div className="form-group">
                <HookFormInput
                  type="date"
                  label="Radera konton avslutade innan"
                  id="terminatedBefore"
                  registerOptions={{ required: true }}
                />
              </div>
              <FormButtonsFooter
                submitText="Radera"
                onClose={onClose}
                handleSubmit={methods.handleSubmit}
                submitSucceeded={false}
                submitting={loading}
              />
            </form>
          </FormProvider>
        )}
      </ButtonModalForm>
    </>
  );
};

export default DeleteLocations;
