import { defineMessages } from 'react-intl';

export default defineMessages({
  placeholderAddItem: {
    id: 'calendar.bkf.search-resource.placeholderAddItem',
    defaultMessage: 'Search resource'
  },
  btnBack: {
    id: 'calendar.bkf.search-resource.btnBack',
    defaultMessage: 'Back'
  },
  lblTitle: {
    id: 'calendar.bkf.search-resource.lblTitle',
    defaultMessage: 'Select resources'
  }
});
