import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import CurrencyUtil from '@Utils/currency-util';
import { getServiceColors } from '@State/calendar-selectors';
import { isBookingCancelled, isClassBooking } from '@Utils/booking-util';
import { txt } from '@Utils/i18n-util';
import { getServiceInitialValues } from './booking-form-util';
import { styleHiddenInput } from './style';
import BookingServiceItem from './booking-service-item';
import msg from './booking-service.msg';

class BookingService extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editService: props.initialValues && props.initialValues.isNew,
      editPrice: false
    };
  }

  componentDidMount() {
    if (this.state.editService) {
      this.focusServiceField();
    }
  }

  focusServiceField = () => {
    if (this.service) {
      const service = ReactDOM.findDOMNode(this.service);
      service.focus();
    }
  };

  focusHiddenField = () => {
    if (this.hidden) {
      const hidden = ReactDOM.findDOMNode(this.hidden);
      hidden.style.visibility = 'visible';
      hidden.focus();
      hidden.style.visibility = 'hidden';
    }
  };

  handleClick = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();

    const { services } = this.props;
    const hasService = services?.size >= 1 && services.first().id;

    if (hasService) {
      this.props.onSearchStart();
    } else {
      this.focusHiddenField();
      this.setState({ editService: true }, () => this.focusServiceField());
    }
  };

  handleEdit = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    this.props.onSearchStart();
  };

  handleClose = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    this.setState({ editService: false, editPrice: false });
  };

  handleChangePrice = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    this.setState({ editPrice: true });
  };

  updateServicePrice = (id) => {
    const service = this.props.services.get(id);
    this.props.onUpdateService({
      ...service,
      price: parseFloat(this.props[`servicePrice${id}`] || 0)
    });
  };

  renderHiddenInput() {
    return <input ref={(ref) => { this.hidden = ref; }} style={styleHiddenInput} />;
  }

  render() {
    const { editService, editPrice } = this.state;
    const { services, name, serviceColors, sales, allowMultiple, allowEdit } = this.props;
    const isClass = isClassBooking(this.props);
    const isCancelled = isBookingCancelled(this.props);
    const isEditable = allowEdit && !isCancelled;
    const hasServices = services && services.size > 0;
    const isPaid = sales?.length > 0;

    if (isCancelled && !(hasServices || name)) {
      return null;
    }

    if (editService) {
      return (
        <div className="booking-form-block form">
          <div className="row tight">
            <div className="form-group col-xs-12">
              <label className="control-label">{txt(msg.lblDescription)}</label>
              <Field name="name" component="input" type="text" className="form-control" ref={(ref) => { this.service = ref; }} />
            </div>
          </div>
          <div className="text-right">
            <button className="btn-label" tabIndex={-1} onClick={this.handleClose}>{txt(msg.btnClose)}</button>
          </div>
        </div>
      );
    }

    if (editPrice) {
      return (
        <div className="booking-form-block form">
          <div className="service-list">
            {services.valueSeq().map(service => {
              return (
                <div key={service.id} className="service-item edit">
                  <div className="row">
                    <div className="col-xs-7">
                      <div className="form-control-static">
                        <h4 className="mt0 mb0">{service.name}</h4>
                      </div>
                    </div>
                    <div className="col-xs-5">
                      <div className="form-control-container">
                        <label className="form-control-label">{CurrencyUtil.currencySymbol()}</label>
                        <Field name={`servicePrice${service.id}`} component="input" type="number" className="form-control" onBlur={() => this.updateServicePrice(service.id)} />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="text-right mt2">
            <button className="btn-label" tabIndex={-1} onClick={this.handleClose}>{txt(msg.btnClose)}</button>
          </div>
        </div>
      );
    }

    if (!hasServices && !name) {
      return (
        <button className="booking-form-block button" onClick={this.props.onSearchStart}>
          <i className="fa fa-cog" /> {allowMultiple ? txt(msg.btnAddService) : txt(msg.btnSelectService)}
        </button>
      );
    }

    return (
      <div className={isEditable ? 'booking-form-block' : 'booking-form-block cancelled'} onClick={isEditable ? this.handleClick : null}>
        {!isCancelled && (
          <div className="block-buttons" onClick={ev => ev.stopPropagation()}>
            <button className="btn-label" tabIndex={-1} onClick={this.handleEdit}>
              {isClass ? txt(msg.btnChange) : txt(msg.btnAddRemove)}
            </button>
            <br />
            {hasServices && !isPaid && (
              <button className="btn-label" tabIndex={-1} onClick={this.handleChangePrice}>{txt(msg.btnChangePrice)}</button>
            )}
          </div>
        )}
        {hasServices ? (
          <div className="service-list">
            {services.valueSeq().map(service => {
              return (
                <BookingServiceItem
                  key={service.id}
                  serviceColors={serviceColors}
                  {...service}
                />
              );
            })}
          </div>
        ) : (
          <BookingServiceItem
            name={name}
          />
        )}
        {this.renderHiddenInput()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { bkf } = state;

  return {
    type: bkf.get('type'),
    status: bkf.get('status'),
    initialValues: getServiceInitialValues(state),
    services: bkf.get('services'),
    sales: bkf.get('sales'),
    serviceColors: getServiceColors(state),
    ...getFormValues('bkf-service')(state)
  };
};

export default connect(mapStateToProps)(reduxForm({
  form: 'bkf-service',
  destroyOnUnmount: false
})(BookingService));
