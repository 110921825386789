import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmbeddedInApp } from '@Utils/embedded-util';
import { handleInvalidToken } from '@Utils/ajax-util';
import { getAllCookies } from '@Utils/cookies';
import { logMeOut } from '@Utils/account';

const AuthRequired = () => {
  const dispatch = useDispatch();
  const embeddedInApp = useSelector(isEmbeddedInApp);

  const handleLogin = (ev) => {
    ev.preventDefault();

    if (embeddedInApp) {
      return dispatch(handleInvalidToken(false));
    }

    logMeOut();
  };

  return (
    <div className="dialog-container">
      {embeddedInApp ? (
        <div className="dialog-content">
          <h2>
            Fel vid uppstart
          </h2>
          <hr />
          <p>
            Cliento kunde inte startas. Vänligen försök igen
          </p>
          <hr />
          <a href="#" onClick={handleLogin} className="button arrow">Försök igen</a>

          <br /><br />
          <h4>Debug information</h4>
          <pre>
            {JSON.stringify(getAllCookies(), null, 2)}
          </pre>
        </div>
      ) : (
        <div className="dialog-content">
          <h2>
            <i className="fa fa-sign-in" /> Logga in
          </h2>
          <hr />
          <p>
            Vi kunde inte verifiera ditt användarnamn och lösenord.
            <br />
            <br />
            Vänligen logga in på nytt.
          </p>
          <hr />
          <a href="#" onClick={handleLogin} className="button arrow">Logga in</a>
        </div>
      )}
    </div>
  );
};

export default AuthRequired;
