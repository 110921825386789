import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, getFormValues, reduxForm } from 'redux-form';
import ReactDOM from 'react-dom';
import { bkfModals } from '@State/bkf/constants';
import { toggleBKFModal } from '@State/bkf/actions';
import { txt } from '@Utils/i18n-util';
import { isBookingNew, getClassStatus } from '@Utils/booking-util';
import { styleHiddenInput } from './style';

import msg from './booking-class-info.msg';

class BookingClassInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editInfo: false
    };
  }

  focusFirstField = () => {
    if (this.maxSlots) {
      const maxSlots = ReactDOM.findDOMNode(this.maxSlots);
      maxSlots.focus();
    }
  };

  focusHiddenField = () => {
    if (this.hidden) {
      const hidden = ReactDOM.findDOMNode(this.hidden);
      hidden.style.visibility = 'visible';
      hidden.focus();
      hidden.style.visibility = 'hidden';
    }
  };

  handleEdit = (ev) => {
    ev.preventDefault();
    this.focusHiddenField();
    this.setState({ editInfo: true }, () => this.focusFirstField());
  };

  handleOpen = (ev) => {
    ev.preventDefault();
    this.props.onShowClass();
  };

  handleClose = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    this.setState({ editInfo: false });
  };

  renderHiddenInput() {
    return <input ref={(ref) => { this.hidden = ref; }} style={styleHiddenInput} />;
  }

  render() {
    const { editInfo } = this.state;
    const { maxSlots, customers, services } = this.props;
    const hasServices = services && services.size > 0;
    const isNew = isBookingNew(this.props);
    const status = getClassStatus(customers.length, maxSlots);

    if (!hasServices) {
      return null;
    }

    if (editInfo) {
      return (
        <div className="booking-form-block form">
          <div className="row tight">
            <div className="form-group col-xs-6">
              <label className="control-label">{txt(msg.maxSlots)}</label>
              <Field name="maxSlots" component="input" type="number" className="form-control" ref={(ref) => { this.maxSlots = ref; }} />
            </div>
          </div>
          <div className="text-right">
            <button className="btn-label" tabIndex={-1} onClick={this.handleClose}>{txt(msg.closeBtnLabel)}</button>
          </div>
        </div>
      );
    }

    return (
      <div className="booking-form-block" onClick={isNew ? this.handleEdit : this.handleOpen}>
        <div className="block-buttons">
          {isNew ? (
            <button className="btn-label" tabIndex={-1} onClick={this.handleEdit}>{txt(msg.editBtnLabel)}</button>
          ) : (
            <button className="btn-label" tabIndex={-1} onClick={this.handleOpen}>{txt(msg.openBtnLabel)}</button>
          )}
        </div>
        {isNew ? (
          <h4>{txt(msg.classBooking)}</h4>
        ) : (
          <h4>
            <span className="color-dot" style={{ background: status.color }} />
            {status.description}
          </h4>
        )}
        {isNew ? (
          <div>{txt(msg.maxSlots)}: {maxSlots || '0'}</div>
        ) : (
          <div>{customers.length || '0'} av {maxSlots || '0'} {txt(msg.bookedSlots)}</div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { bkf, bookingsById } = state;

  return {
    id: bkf.get('id'),
    maxSlots: bkf.get('maxSlots'),
    customers: bookingsById.get(bkf.get('id')).customers || [],
    services: bkf.get('services'),
    initialValues: bkf.get('service'),
    ...getFormValues('bkf-class-info')(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onShowClass: () => dispatch(toggleBKFModal(bkfModals.class, true))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'bkf-class-info',
  destroyOnUnmount: false
})(BookingClassInfo));
