import React, {
  memo, useContext, useEffect, useState
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import { getCustomerBookingUrl } from '@Utils/navigate';
import { fetchBookingsHistory } from '@State/customer-actions';
import customerIdContext from '@Components/customers/customer-id-context';
import { getSortedBookings, groupEventsByMonth, getFutureSortedBookings } from '@Utils/customer-util';

import Row from '@Components/ui/styled/row';
import CustomerBooking from '@Components/customers/customer-detail/bookings/customer-booking';
import CustomerEmptyTab from '@Components/customers/customer-detail/customer-empty-tab';
import CustomerFutureBookings from '@Components/customers/customer-detail/bookings/customer-future-bookings';
import CustomerPastBookings from '@Components/customers/customer-detail/bookings/customer-past-bookings';
import Loader from '@Components/ui/loader';
import {
  CustomerFutureWrapper, ScrollContent, ScrollWrapper, ColumnRight, ColumnLeft, ContentBlock, TitleBlock
} from '@Components/customers/customer-styles';
import { txt } from '@Utils/i18n-util';
import msg from './customer-bookings.msg';

const CustomerBookings = ({ routeParams, tabId, onSelectBooking }) => {
  const dispatch = useDispatch();
  const id = tabId || routeParams?.tabId;
  const customerIdStore = +useContext(customerIdContext);
  const customerBookingsStore = useSelector((state) => state.customerBookings);
  const phoneMode = useSelector(state => state.mainViewState.get('phoneMode'));

  const bookings = customerBookingsStore.get('bookings').toJS();
  const [loading, setLoading] = useState(customerBookingsStore.get('loading'));
  const [futureBookings, setFutureBookings] = useState(getFutureSortedBookings(bookings));
  const [pastBookings, setPastBookings] = useState(groupEventsByMonth(getSortedBookings(bookings, false)));

  const getSelectedEvent = (selectedId) => {
    const bookings = customerBookingsStore.get('bookings').toJS();
    if (bookings && bookings.length > 0) {
      const futureBookings = getFutureSortedBookings(bookings);
      const pastBookings = groupEventsByMonth(getSortedBookings(bookings, false));
      const newBooking = bookings.find((book) => book.id === +selectedId);

      if (selectedId && !isEmpty(bookings) && newBooking) {
        return newBooking;
      }
      if (!isEmpty(futureBookings)) return futureBookings[0];
      if (!isEmpty(pastBookings)) return pastBookings[0][1][0];
      if (!isEmpty(bookings)) return bookings[0];
    }
  };

  useEffect(() => {
    dispatch(fetchBookingsHistory(customerIdStore));
  }, [customerIdStore]);

  useEffect(() => {
    setLoading(customerBookingsStore.get('loading'));
    const bookingsNew = customerBookingsStore.get('bookings').toJS();
    const futureBookingsNew = getFutureSortedBookings(bookingsNew);
    if (!isEqual(futureBookings, futureBookingsNew)) {
      setFutureBookings(futureBookingsNew);
    }

    const pastBookingsNew = groupEventsByMonth(getSortedBookings(bookingsNew, false));
    if (!isEqual(pastBookingsNew, pastBookings)) {
      setPastBookings(pastBookingsNew);
    }

    if (bookingsNew && bookingsNew.length > 0) {
      const selectedNew = getSelectedEvent(id);
      if (!isEqual(selectedNew, selectedEvent)) {
        setSelectedEvent(selectedNew);
      }
    } else {
      setSelectedEvent(null);
    }
  }, [customerBookingsStore]);

  const [selectedEvent, setSelectedEvent] = useState(getSelectedEvent(id));

  const handlerOnClick = (item) => {
    setSelectedEvent(item);
    onSelectBooking(item.id, 'bookings');
  };

  if (bookings && bookings.length > 0 && routeParams && !routeParams?.tabId && selectedEvent) {
    return <Redirect to={getCustomerBookingUrl(routeParams, selectedEvent?.id)} />;
  }

  if (loading) {
    return <Loader topPosition />;
  }

  if (!selectedEvent) {
    return (
      <CustomerEmptyTab
        title={txt(msg.noBookingsTitle)}
        text={txt(msg.noBookingsText)}
      />
    );
  }

  const renderDetailBooking = () => {
    return (
      <>
        <TitleBlock>{txt(msg.lblDetails)}</TitleBlock>
        <ContentBlock>
          <CustomerBooking customerId={customerIdStore} {...selectedEvent} />
        </ContentBlock>
      </>
    );
  };
  const renderBookings = () => (
    <>
      {futureBookings.length > 0 && (
      <CustomerFutureWrapper>
        <CustomerFutureBookings
          isSelectable
          selectedEventId={selectedEvent?.id}
          onSelectEvent={handlerOnClick}
          items={futureBookings}
        />
      </CustomerFutureWrapper>
      )}
      <CustomerPastBookings
        isSelectable
        selectedEventId={selectedEvent?.id}
        onSelectEvent={handlerOnClick}
        items={pastBookings}
      />
    </>
  );

  if (phoneMode) {
    return (
      <Row scrollContainer>
        <ScrollWrapper>
          <ScrollContent>
            {renderBookings()}
          </ScrollContent>
        </ScrollWrapper>
      </Row>
    );
  }

  return (
    <Row scrollContainer>
      <ColumnLeft>
        <ScrollWrapper>
          <ScrollContent>
            {renderBookings()}
          </ScrollContent>
        </ScrollWrapper>
      </ColumnLeft>
      <ColumnRight>
        <ScrollWrapper>
          <ScrollContent>
            {renderDetailBooking()}
          </ScrollContent>
        </ScrollWrapper>
      </ColumnRight>
    </Row>
  );
};

export default memo(
  CustomerBookings,
  (prevProps, nextProps) => !!isEqual(prevProps, nextProps)
);
