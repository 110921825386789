import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { setLastVisitedPage } from '@Utils/session';
import { fetchFortnoxAuthUrl, refreshFortnoxTokens } from '@State/fortnox-actions';
import { clearFeatureCache, createAllFortnoxInvoices, refreshYabieContracts } from '@State/sysadmin-actions';
import ProgressButton from '@Components/ui/progress-button';
import ConfirmActionModal from '@Components/ui/confirm-action-modal';
import Button from '@Components/ui/button';
import AccountReport from './account-report';
import DeleteLocations from './delete-locations';
import PurgeDeletedData from './purge-deleted-data';

const Tools = () => {
  const dispatch = useDispatch();
  const [showCreateInvoices, setShowCreateInvoices] = useState(false);

  const onConnectFortnox = () => dispatch(fetchFortnoxAuthUrl())
    .then(({ linkUrl }) => {
      setLastVisitedPage(window.location.pathname);
      window.location = linkUrl;
    });

  const onClearFeatureCache = () => {
    return Promise.all([
      dispatch(clearFeatureCache(false)),
      dispatch(clearFeatureCache(true))
    ]);
  };

  const onRefreshYabieContracts = () => dispatch(refreshYabieContracts());

  return (
    <div className="columns-wrapper">
      <div className="columns-container">
        <div className="columns-content">
          <div className="columns-content-container width-large">
            <div className="columns-content-body with-padding">
              <div className="row">
                <div className="col-xs-6">
                  <h4>Fakturor</h4>
                  <p>
                    <Button gray small onClick={() => setShowCreateInvoices(true)}>
                      Skapa alla fakturor
                    </Button>
                    {showCreateInvoices && (
                      <ConfirmActionModal
                        onConfirm={() => dispatch(createAllFortnoxInvoices())}
                        onCancel={() => setShowCreateInvoices(false)}
                        confirmMessage="Vill du skapa fakturor i Fortnox för alla fakturakonton?"
                        successMessage="Fakturor skapade"
                        confirmButtonText="Skapa fakturor"
                      />
                    )}
                  </p>
                  <p>
                    <ProgressButton gray small onClick={onRefreshYabieContracts}>
                      Återskapa Yabie invoice items
                    </ProgressButton>
                  </p>
                  <h4 className="mt5">Rapporter</h4>
                  <p>
                    <AccountReport upload />
                  </p>
                  <p>
                    <AccountReport />
                  </p>
                  <h4 className="mt5">Cache</h4>
                  <p>
                    <ProgressButton gray small onClick={onClearFeatureCache}>
                      Rensa feature cache
                    </ProgressButton>
                  </p>
                </div>
                <div className="col-xs-6">
                  <h4>Fortnox</h4>
                  <p>
                    <ProgressButton gray small onClick={onConnectFortnox}>
                      Koppla Fortnox-konto
                    </ProgressButton>
                  </p>
                  <p>
                    <ProgressButton gray small onClick={() => dispatch(refreshFortnoxTokens())}>
                      Uppdatera Fortnox-tokens
                    </ProgressButton>
                  </p>
                  <h4 className="mt5">Radera</h4>
                  <p>
                    <DeleteLocations />
                  </p>
                  <p>
                    <PurgeDeletedData />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tools;
