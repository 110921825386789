import axios from 'axios';
import {
  axiosDefault, axiosErrorHandler, checkStatus, checkStatusAxios, fetchErrorHandler, fetchGet,
  prefixUrl
} from '@Utils/ajax-util';
import { initLocationConfig } from '@Utils/i18n-util';
import { initTemplates } from '@Utils/handlebars';
import { setUserPreferences } from './user-actions';
import { setOperator } from './pos-actions';

export const ACCOUNT_STATUS = 'ACCOUNT_STATUS';
export const LOC_CONFIG_CHANGED = 'LOC_CONFIG_CHANGED';
export const RECEIVE_LOC_OPTIONS = 'RECEIVE_LOC_OPTIONS';
export const CLEAR_LOCATION_STATE = 'CLEAR_LOCATION_STATE';
export const FEATURES_LOADED = 'FEATURES_LOADED';
export const LOCATION_NAMES_LOADED = 'LOCATION_NAMES_LOADED';

export function changeAccountStatus(accountStatus, trialStatus, trialUntil, features) {
  return {
    type: ACCOUNT_STATUS,
    state: {
      accountStatus,
      trialStatus,
      trialUntil,
      features
    }
  };
}

export function receiveOptions(locations) {
  return {
    type: RECEIVE_LOC_OPTIONS,
    state: {
      locations
    }
  };
}

export function fetchLocationOptions() {
  const url = prefixUrl('/auth/locations/');

  return (dispatch) => {
    return fetch(url, fetchGet())
      .then(res => dispatch(checkStatus(res)))
      .then(res => res.json())
      .then(res => dispatch(receiveOptions(res)))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function clearLocationState() {
  return {
    type: CLEAR_LOCATION_STATE
  };
}

export function featuresLoaded(features, source = 'local') {
  return {
    type: FEATURES_LOADED,
    features,
    source
  };
}

export function locationNamesLoaded(locationNames, source = 'local') {
  return {
    type: LOCATION_NAMES_LOADED,
    locationNames,
    source
  };
}

export function configChanged(prefs, source = 'local') {
  return {
    type: LOC_CONFIG_CHANGED,
    state: prefs,
    source
  };
}

export function fetchLocationConfig() {
  const url = prefixUrl('/location/configuration');
  const config = axiosDefault();

  return (dispatch) => {
    return axios.get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then((res) => {
        initLocationConfig(res.configuration);
        initTemplates(res.templates);

        dispatch(featuresLoaded(res.features));
        dispatch(locationNamesLoaded(res.locationNames));
        dispatch(configChanged(res.configuration));
        dispatch(setUserPreferences(res.clientPrefs));
        dispatch(setOperator(res.operator));
      })
      .catch(error => {
        axiosErrorHandler(error, dispatch);
        throw error;
      });
  };
}

export function dismissOnboarding() {
  const url = prefixUrl('/location/onboarded');
  const config = axiosDefault();

  return (dispatch) => {
    dispatch(configChanged({ onboarded: true }));
    return axios.put(url, null, config)
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function setLocationConfig(configuration) {
  const url = prefixUrl('/location/configuration');
  const config = axiosDefault();

  return (dispatch) => {
    return axios.put(url, configuration, config)
      .then(() => dispatch(configChanged(configuration)))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}
