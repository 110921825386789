import React, { useState } from 'react';
import styled from 'styled-components';
import ModalDialog from '@Components/dialogs/modal-dialog';
import SearchCustomer from '@Components/calendar/booking/search-customer';
import AddCustomerModal from '@Components/customers/add-customer-modal';

const Container = styled.div`
  .booking-form-body {
    padding: 0;
  }
`;

const SearchCustomerModal = ({ onCancel, onSelected, routeParams, ...props }) => {
  const [showAddCustomer, setShowAddCustomer] = useState(false);

  const handleSelected = ({ customer }) => {
    if (customer.customerId) {
      onSelected(customer);
    } else {
      setShowAddCustomer(true);
    }
  };

  if (showAddCustomer) {
    return (
      <AddCustomerModal
        routeParams={routeParams}
        onCreated={(customer) => onSelected(customer)}
        onClose={() => setShowAddCustomer(false)}
      />
    );
  }

  return (
    <ModalDialog
      contentSize="medium"
      title="Lägg till kund"
      onClose={onCancel}
      closeButtonText="Stäng"
    >
      <Container>
        <SearchCustomer
          noChrome
          onSelected={handleSelected}
          {...props}
        />
      </Container>
    </ModalDialog>
  );
};

export default SearchCustomerModal;
