import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { colors } from '@Components/ui/styled/variables';
import { getClassStatus } from '@Utils/booking-util';
import ConfirmPopover from '@Components/ui/confirm-popover';
import { setClassBookingOpen } from '@State/booking-actions';

const BookingClassStatus = () => {
  const dispatch = useDispatch();
  const bookingId = useSelector(state => state.bkf.get('id'));
  const maxSlots = useSelector(state => state.bkf.get('maxSlots'));
  const customers = useSelector(state => state.bookingsById.get(bookingId).customers || []);

  const [showConfirm, setShowConfirm] = useState(false);
  const [isOpen, setIsOpen] = useState(true);

  const openColor = isOpen ? colors.greenCircle : colors.danger;
  const status = getClassStatus(customers.length, maxSlots);

  const onOpenBooking = () => {
    return dispatch(setClassBookingOpen(bookingId, true));
  };

  const onCloseBooking = () => {
    return dispatch(setClassBookingOpen(bookingId, false))
      .then(() => setShowConfirm(false));
  };

  return (
    <div className="booking-class-status">
      <section>
        <h5>
          <span className="color-dot" style={{ background: status.color }} />
          {customers.length} av {maxSlots} bokade
        </h5>
        <button type="button" className="btn btn-default">Ändra platser</button>
      </section>
      <section>
        <h5>
          <span className="color-dot" style={{ background: openColor }} />
          {isOpen ? 'Öppen för bokning' : 'Stängd för bokning'}
        </h5>
        {isOpen ? (
          <ConfirmPopover
            text="Vill du stänga klassen för bokning?"
            confirmText="Stäng klass"
            onClose={() => setShowConfirm(false)}
            onConfirm={onCloseBooking}
            isOpen={showConfirm}
          >
            <button type="button" className="btn btn-danger" onClick={() => setShowConfirm(true)}>
              Stäng klass
            </button>
          </ConfirmPopover>
        ) : (
          <button type="button" className="btn btn-default" onClick={onOpenBooking}>
            Öppna klass
          </button>
        )}
      </section>
    </div>
  );
};

export default BookingClassStatus;
