import { colors } from '@Components/ui/styled/variables';

export const NEW_BOOKING = 'DRAGGER';
export const isBookingNew = (props) => props.id === NEW_BOOKING;
export const isBookingCancelled = (props) => {
  const { attributes, status } = props;
  return (attributes?.status || status) === 'Cancelled';
};

const isType = ({ attributes, type }, value) => (attributes?.type || type) === value;
export const isSimpleBooking = (props) => isType(props, bookingTypes.SimpleBooking);
export const isClassBooking = (props) => isType(props, bookingTypes.ClassBooking);
export const isBookingReservation = (props) => isType(props, bookingTypes.Reservation);
export const isScheduleException = (props) => isType(props, bookingTypes.ScheduleException);

export const bookingTypes = {
  SimpleBooking: 'SimpleBooking',
  ClassBooking: 'ClassBooking',
  Reservation: 'Reservation',
  ScheduleException: 'ScheduleException'
};

export const scheduleOpenTypes = {
  Normal: 'Normal',
  Overtime: 'Overtime'
};

export const scheduleTimeTypes = {
  Scheduled: 'Schemalagd tid',
  Journaled: 'Närvarotid',
  Overtime: 'Övertid',
  Qualifying: 'Karenstid',
  SickLeave80: 'Sjuk',
  SickChildLeave: 'VAB',
  Vacation: 'Semester',
  OnLeave: 'Tjänstledig',
  Furlough: 'Permission',
  WorkingHoursReduction: 'ATF',
  Absence: 'Frånvaro'
};

export function getDescription(description, services) {
  const hasServices = services && services.length > 0;
  const serviceDescriptions = hasServices ? services.map(s => s.name).join(', ') : '';
  return description || serviceDescriptions;
}

export function getSingleCustomer(props) {
  return isSimpleBooking(props) && props.customers?.length === 1
    ? props.customers[0]
    : null;
}

export function getClassStatus(bookedSlots = 0, maxSlots = 0) {
  if (bookedSlots === maxSlots) {
    return {
      color: colors.danger,
      description: 'Fullbokad',
      available: false
    };
  }
  if (bookedSlots / maxSlots >= 0.7) {
    return {
      color: colors.bgWarning,
      description: 'Fåtal platser kvar',
      available: true
    };
  }
  return {
    color: colors.greenCircle,
    description: 'Platser kvar',
    available: true
  };
}
