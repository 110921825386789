import moment from 'moment';
import { createSelector } from 'reselect';
import { config, user } from '@Utils/preference-keys';
import { hasPermission, CHANGE_ALL_CUSTOMERS, CHANGE_JSON_PREFS } from '@Utils/permissions';
import { getBookingIdFromHash, translateViewDate } from '../utils/url-util';

/* THIS IS A VERY IMPORTANT PERF OPTIMIZATION - DONT MESS */

export const routeParams = createSelector(
  params => params.org,
  params => params.loc,
  params => params.section,
  params => params.subsection,
  params => params.viewMode,
  params => params.viewDate,
  params => params.entityType,
  params => params.entityId,
  (params, location) => location?.hash || '',
  (params, location) => location?.search || '',
  (org, loc, section, subsection, viewMode, viewDate, entityType, entityId, hash, search) => {
    return {
      org,
      loc,
      section,
      subsection,
      viewMode,
      viewDate: translateViewDate(viewMode, viewDate),
      entityType,
      entityId: parseInt(entityId),
      bookingId: getBookingIdFromHash(hash),
      search
    };
  }
);

const getConfig = state => state.locationConfig;
const getOptions = state => state.locationOptions;
export const getTrialStatus = state => state.appState.get('trialStatus');
export const getAccountStatus = state => state.appState.get('accountStatus');
export const getStaffJournalStatus = state => state.mainViewState.get('staffJournalStatus');
export const getSysAdmin = state => state.authState.get('systemRole') === 'SystemAdmin';

export const getCurrentVersion = state => state.appState.get('currentVersion') || -1;
export const getRequiredVersion = state => state.appState.get('requiredVersion') || -1;

export const getIsVersionMismatch = createSelector(
  getCurrentVersion,
  getRequiredVersion,
  (currentVersion, requiredVersion) => {
    return requiredVersion && currentVersion !== requiredVersion;
  }
);

export const getRouteParams = (state, props) => {
  return props.routeParams || routeParams(props.match.params, props.location);
};

export const getOrgWideCustomerDb = createSelector(
  getConfig,
  (locationConfig) => {
    return locationConfig.get(config.orgWideCustomerDb);
  }
);

export const getCurrentLocation = (state, props) => {
  const orgLocSlug = `${props.routeParams.org}/${props.routeParams.loc}`;
  return state.locationOptions.get(orgLocSlug);
};

export const getFeatures = createSelector(
  state => state.locationFeatures,
  features => features.toJS()
);

export const getWebPaymentEnabled = createSelector(
  getFeatures,
  (features) => features.EnableEcomPayments
);

const emptyArray = [];
export const getPermissions = createSelector(
  getRouteParams,
  getOptions,
  (routeParams, locationOptions) => {
    const { org, loc } = routeParams;
    return locationOptions.size > 0 ? locationOptions.get(`${org}/${loc}`).permissions : emptyArray;
  }
);

export const getSmsEnabled = createSelector(
  getConfig,
  getTrialStatus,
  (locationConfig, trialStatus) => {
    const smsEnabled = locationConfig.get(config.smsBillingEnabled)
      || trialStatus === 'Trial';

    return smsEnabled && locationConfig.get(config.allowSms);
  }
);

export const getSmsCampaignEnabled = createSelector(
  getConfig,
  getSmsEnabled,
  (locationConfig, smsEnabled) => {
    return smsEnabled && locationConfig.get(config.allowSmsCampaign);
  }
);

export const getEmailCampaignEnabled = createSelector(
  getConfig,
  (locationConfig) => {
    return locationConfig.get(config.allowEmailCampaign);
  }
);

export const getCampaignEnabled = createSelector(
  getSmsCampaignEnabled,
  getEmailCampaignEnabled,
  (smsCampaignEnabled, emailCampaignEnabled) => {
    return smsCampaignEnabled || emailCampaignEnabled;
  }
);

export const getLocationName = createSelector(
  getConfig,
  (locationConfig) => {
    const locationName = locationConfig.get(config.locationName);
    const organisationName = locationConfig.get(config.organisationName);

    return organisationName !== locationName ? `${organisationName} (${locationName})` : organisationName;
  }
);

const getOrderedGroups = state => state.orderedGroups;
const getResourcesById = state => state.resourcesById;

export const getCalendarTitle = createSelector(
  getRouteParams,
  getOrderedGroups,
  getResourcesById,
  (routeParams, orderedGroups, resourcesById) => {
    const { entityType, entityId } = routeParams;
    if (entityType === 'group') {
      const group = orderedGroups.find(g => g.get('id') === entityId);
      return group ? group.get('name') : null;
    }
    if (entityType === 'my-resource' || entityType === 'resource') {
      const resource = resourcesById.get(entityId);
      return resource ? resource.name : null;
    }
    return null;
  }
);

export const getShowFeaturesOnboarding = createSelector(
  getConfig,
  getFeatures,
  getSysAdmin,
  (locationConfig, locationFeatures, isSysAdmin) => {
    return !locationConfig.get(config.onboarded)
      && locationFeatures.ShowAddons && !isSysAdmin;
  }
);

export const getCustomerDetailsPermission = createSelector(
  getPermissions,
  (permissions) => {
    return hasPermission(permissions, CHANGE_ALL_CUSTOMERS);
  }
);

export const getChangeJsonPrefsPermission = createSelector(
  getSysAdmin,
  getPermissions,
  (isSysAdmin, permissions) => {
    return isSysAdmin || hasPermission(permissions, CHANGE_JSON_PREFS);
  }
);

export const getVatRates = createSelector(
  getConfig,
  (locationConfig) => {
    const vatArray = [];
    const vatRates = locationConfig.get(config.currentVatRates);
    const vatKeys = ['Standard', 'ReducedA', 'ReducedB', 'ReducedC'];

    vatKeys.forEach((key) => {
      if (vatRates?.get(key) > 0) {
        vatArray.push(vatRates.get(key));
      }
    });

    vatArray.push(0.00);
    return vatArray;
  }
);

export const getDefaultVatPct = createSelector(
  getVatRates,
  (vatRates) => {
    return vatRates[0];
  }
);

export const getReservationTypes = createSelector(
  getConfig,
  (locationConfig) => {
    return locationConfig.get(config.bookingReservationTypes);
  }
);

export const getVoucherTemplateDefaultValues = createSelector(
  getDefaultVatPct,
  (defaultVatPct) => {
    return {
      voucherType: 'GiftCard',
      accountingType: 'SinglePurpose',
      validityPeriod: 'P1Y',
      vatPct: defaultVatPct
    };
  }
);

export const getShowReleaseNotesNotification = createSelector(
  state => state.locationConfig.get(config.releaseNote),
  state => state.userClientPreferences.getIn(['user', user.releaseNotesDismissed]),

  (releaseNote, releaseNotesDismissed) => {
    if (!releaseNote) {
      return null;
    }
    if (releaseNote && !releaseNotesDismissed) {
      return releaseNote.get('label');
    }
    return moment(releaseNote.get('date')).isAfter(releaseNotesDismissed)
      ? releaseNote.get('label')
      : null;
  }
);
