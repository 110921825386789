import moment from 'moment/moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import deepEqual from 'fast-deep-equal';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { txt } from '@Utils/i18n-util';

import {
  addBooking,
  clearAndCloseBKF,
  deleteBooking,
  setBKFVehicle,
  setBKFBookingTime,
  setBKFCompany,
  setBKFCustomer,
  setBKFService,
  addBKFService,
  removeBKFService,
  updateBKFService,
  updateBooking,
  fetchBookingSale,
  fetchBookingReminders,
  addBKFResource,
  removeBKFResource,
  resetBKFNewResource,
  addBookingCustomer,
  removeBookingCustomer,
  setBKFProp
} from '@State/bkf/actions';

import { getSectionUrl, navigate } from '@Utils/navigate';
import { getScheduleBounds } from '@Utils/schedule-utils';
import { editSchedule } from '@State/schedule-actions';
import {
  NEW_BOOKING, isBookingNew, isSimpleBooking,
  isBookingReservation, isScheduleException, isClassBooking
} from '@Utils/booking-util';
import {
  bookingMoved, bookingTypeChanged, removeTempBooking,
  sendBookingConfirmation
} from '@State/booking-actions';

import { queueBookingPayment } from '@State/pos-actions';
import { isEmbeddedInExternalPos } from '@Utils/embedded-util';
import { postWebkitMessage } from '@Utils/wk-embed-bridges';
import {
  getBookingData, getBookingTitle, getConfirmations, getCustomer,
  getSaveBtnText, getScheduleData, getService, getServiceProps, validateBooking,
  shouldSendConfirmations, updateResourcesFromServices, updateTimeAndPriceFromServices
} from './booking-form-util';

import BookingType from './booking-type';
import BookingFlags from './booking-flags';
import BookingInfo from './booking-info';
import BookingVehicle from './booking-vehicle';
import BookingCompany from './booking-company';
import BookingCustomer from './booking-customer';
import BookingCustomFields from './booking-custom-fields';
import BookingService from './booking-service';
import BookingClassInfo from './booking-class-info';
import BookingPrice from './booking-price';
import BookingReview from './booking-review';
import BookingReservationType from './booking-reservation-type';
import ScheduleException from './schedule-exception';
import BookingNotes from './booking-notes';
import BookingAllDay from './booking-all-day';
import BookingOptions from './booking-options';
import BookingConfirmation from './booking-confirmation';
import SearchCustomer from './search-customer';
import SearchService from './search-service';
import SearchResource from './search-resource';
import BookingResource from './booking-resource';
import msg from './booking-form.msg';

class BookingForm extends Component {
  static propTypes = {
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    resourceId: PropTypes.number.isRequired,
    startTime: PropTypes.object.isRequired,
    endTime: PropTypes.object.isRequired,
    attributes: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onUpdateBooking: PropTypes.func.isRequired,
    onDeleteBooking: PropTypes.func.isRequired,
    deviceType: PropTypes.string.isRequired,
    deviceOs: PropTypes.string.isRequired,
    externalKeyboard: PropTypes.bool.isRequired
  };

  constructor(props) {
    super(props);

    this.initialState = isBookingNew(props) ? null : getBookingData(props);

    this.state = {
      isAllDay: false,
      showOptions: false,
      isSaving: false,
      validationErrors: null,
      searchCustomer: false,
      searchCustomerValue: '',
      searchService: false,
      searchResource: false,
      editNotes: false
    };
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);

    this.checkIsAllDay();
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  getMaxHeight = () => this.props.gridClientHeight + 48;

  getBodyHeight = () => 400;

  render() {
    return (
      <div className="booking-form-container">
        {this.renderContent()}
      </div>
    );
  }

  renderContent() {
    if (this.state.searchCustomer) {
      return this.renderSearchCustomer();
    }
    if (this.state.searchService) {
      return this.renderSearchService();
    }
    if (this.state.searchResource) {
      return this.renderSearchResource();
    }
    if (this.state.editNotes) {
      return this.renderEditNotes();
    }
    return this.renderViewBooking();
  }

  renderSearchService() {
    return (
      <>
        <SearchService
          context="Booking"
          allowMultiple={isSimpleBooking(this.props)}
          deviceType={this.props.deviceType}
          deviceOs={this.props.deviceOs}
          externalKeyboard={this.props.externalKeyboard}
          onSelected={this.handleServiceSelect}
          onAdd={this.handleServiceAdd}
          onRemove={this.handleServiceRemove}
          onClear={this.handleClearService}
          onCancel={this.handleCancelSearch}
          maxHeight={this.getMaxHeight()}
          height={!this.props.isModal && this.state.bodyHeight}
          disabled={this.state.isSaving}
        />
        {this.renderSearchFooter()}
      </>
    );
  }

  handleCancelSearch = () => {
    this.setState({ searchService: false, searchResource: false });
  };

  renderSearchResource() {
    return (
      <>
        <SearchResource
          context="Booking"
          primaryResourceId={this.props.resourceId}
          deviceType={this.props.deviceType}
          deviceOs={this.props.deviceOs}
          externalKeyboard={this.props.externalKeyboard}
          onAdd={this.handleResourceAdd}
          onRemove={this.handleResourceRemove}
          onCancel={this.handleCancelSearch}
          maxHeight={this.getMaxHeight()}
          height={!this.props.isModal && this.state.bodyHeight}
          disabled={this.state.isSaving}
        />
        {this.renderSearchFooter()}
      </>
    );
  }

  renderSearchCustomer() {
    return (
      <SearchCustomer
        context="Booking"
        deviceType={this.props.deviceType}
        deviceOs={this.props.deviceOs}
        externalKeyboard={this.props.externalKeyboard}
        value={this.state.searchCustomerValue}
        onSelected={this.handleCustomerSelect}
        onCancel={() => { this.setState({ searchCustomer: false }); }}
        maxHeight={this.getMaxHeight()}
        height={!this.props.isModal && this.state.bodyHeight}
        scope={this.state.searchCustomerScope}
        disabled={this.state.isSaving}
      />
    );
  }

  renderEditNotes() {
    return (
      <BookingNotes
        fullScreen
        onCancel={() => { this.setState({ editNotes: false }); }}
        maxHeight={this.getMaxHeight()}
      />
    );
  }

  renderViewBooking() {
    const {
      type, customer, company, vehicle, isModal, scheduleEditMode,
      enableCompanyBooking, enableVehicleBooking, exceptionType
    } = this.props;
    const { showOptions, isSaving, isAllDay } = this.state;

    const isNew = isBookingNew(this.props);
    const isBooking = isSimpleBooking(this.props);
    const isClass = isClassBooking(this.props);
    const isReservation = isBookingReservation(this.props);
    const isSchedule = isScheduleException(this.props);
    const saveText = isSaving ? txt(msg.savingText) : txt(msg.saveText);

    const showCompany = company || (enableCompanyBooking && customer);
    const showVehicle = vehicle || (enableVehicleBooking && (customer || company));
    const showConfirmations = shouldSendConfirmations(this.props, this.initialState);
    const disableNotes = isSchedule && exceptionType === 'close';

    return (
      <div className={isSaving ? 'booking-form disabled' : 'booking-form'}>
        <div className="booking-form-header mobile">
          <div className="cancel">
            <a href="#" onClick={this.handleClose}>{txt(msg.cancelBtnLabel)}</a>
          </div>
          <h4 className="title">{getBookingTitle(isNew, isReservation, isSchedule)}</h4>
          <div className="save">
            <a href="#" onClick={this.handleSave}>{saveText}</a>
          </div>
        </div>
        <div
          className="booking-form-body"
          ref={ref => { this.bookingFormBody = ref; }}
          style={{ maxHeight: this.getMaxHeight() + 48 }}
        >
          {isNew && !scheduleEditMode && (
            <BookingType onTypeChange={this.props.onTypeChange} currentType={type} />
          )}
          {isReservation && <BookingAllDay isAllDay={isAllDay} onAllDay={this.handleAllDay} />}
          {isSchedule && <ScheduleException isAllDay={isAllDay} onAllDay={this.handleAllDay} />}
          {isBooking && <BookingFlags />}
          {!isSchedule && (
            <>
              <BookingInfo onFetchReminders={this.handleFetchReminders} />
              <BookingResource
                onSearchStart={this.handleResourceSearchStart}
                onResetNewResource={this.props.onResetNewResource}
              />
            </>
          )}
          {isBooking && showVehicle && (
            <BookingVehicle
              onSearchStart={this.handleVehicleSearchStart}
              onClearVehicle={this.handleClearVehicle}
            />
          )}
          {isBooking && showCompany && (
            <BookingCompany
              validationErrors={this.state.validationErrors}
              onSearchStart={this.handleCompanySearchStart}
              onClearCompany={this.handleClearCompany}
            />
          )}
          {isBooking && (
            <BookingCustomer
              onSearchStart={this.handleCustomerSearchStart}
              onClearCustomer={this.handleClearCustomer}
            />
          )}
          {(isBooking || isClass) && (
            <BookingService
              allowEdit={isBooking}
              allowMultiple={isSimpleBooking(this.props)}
              onSearchStart={this.handleServiceSearchStart}
              onUpdateService={this.handleServiceUpdate}
              onClearService={this.handleClearService}
            />
          )}
          {isClass && (
            <BookingClassInfo />
          )}
          {isBooking && (
            <>
              {/* TODO: All of these should be scoped to a customer */}
              <BookingCustomFields />
              <BookingPrice onFetchSale={this.handleFetchSale} />
              <BookingReview />
            </>
          )}
          <BookingNotes isModal={isModal} disabled={disableNotes} onEditNotes={this.handleEditNotes} />
          {isReservation && <BookingReservationType />}
          {showConfirmations && <BookingConfirmation />}

          {this.renderFooter(false)}
        </div>
        {showOptions && <BookingOptions onClose={this.hideOptions} />}
      </div>
    );
  }

  renderSearchFooter = () => {
    return (
      <div className="booking-form-footer search-service">
        <div />
        <div className="buttons">
          <button type="submit" className="btn-save" onClick={this.handleCancelSearch}>
            {txt(msg.backBtnLabel)}
          </button>
        </div>
      </div>
    );
  }

  renderFooter = (isSearching) => {
    const { isSaving } = this.state;
    const { exceptionType, services, sales, showPos } = this.props;

    const isNew = isBookingNew(this.props);
    const isBooking = isSimpleBooking(this.props);
    const isClass = isClassBooking(this.props);
    const saveText = getSaveBtnText(exceptionType, isSaving);
    const disabled = isSearching && isSaving;
    const hasSale = sales?.length > 0;
    const hasServices = services?.size > 0;
    const saveDisabled = isNew && isClass && !hasServices;

    return (
      <div className={disabled ? 'booking-form-footer disabled' : 'booking-form-footer'}>
        <div className="options">
          {!isNew && !isSearching && (
            <button type="button" className="btn btn-default" onClick={this.showOptions} tabIndex={13}>
              {txt(msg.optionsBtnLabel)}
            </button>
          )}
        </div>
        <div className="buttons">
          <button type="button" className="btn-cancel" onClick={this.handleClose} tabIndex={12}>
            {txt(msg.cancelBtnLabel)}
          </button>
          {!isNew && isBooking && !hasSale && showPos && !isSearching && (
            <button type="button" className="btn-pay" onClick={this.sendToPos} tabIndex={11}>
              {txt(msg.takePaymentBtnLabel)}
            </button>
          )}
          <button type="submit" className="btn-save" onClick={this.handleSave} tabIndex={10} disabled={saveDisabled}>
            {saveText}
          </button>
        </div>
      </div>
    );
  }

  sendToPos = () => {
    const bookingId = this.props.id;
    const { customerId, name, email, phoneNumber } = getCustomer(this.props) || {};
    const customer = customerId ? {
      customerId,
      name,
      email,
      phoneNumber
    } : null;

    if (this.props.embeddedInExternalPos) {
      const { services } = getServiceProps(getService(this.props), this.props.services);
      postWebkitMessage('sendToPos', { bookingId, customer, services });
      this.props.onClose();
    } else if (this.props.enablePos) {
      this.updateBooking()
        .then(() => {
          this.props.queueBookingPayment({ bookingId, customer });
          navigate(getSectionUrl(this.props.routeParams, 'pos'));
        });
    } else {
      navigate(getSectionUrl(this.props.routeParams, 'pos'));
    }
  };

  showOptions = (ev) => {
    ev && ev.preventDefault();
    this.setState({ showOptions: true });
  };

  hideOptions = (ev) => {
    ev && ev.preventDefault();
    this.setState({ showOptions: false });
  };

  handleDelete = (ev) => {
    ev.preventDefault();
    this.props.onDeleteBooking(this.props.id);
  };

  handleAllDay = () => {
    const { schedulesByResource, resourceId, startTime } = this.props;
    const bounds = getScheduleBounds(schedulesByResource, resourceId, startTime);

    if (bounds.startTime && bounds.endTime) {
      this.props.onSetBookingTime(this.props.id, bounds);
      this.setState({ isAllDay: true });
    }
  };

  checkIsAllDay = () => {
    const { schedulesByResource, resourceId, startTime, endTime } = this.props;
    const bounds = getScheduleBounds(schedulesByResource, resourceId, startTime);

    if (startTime.isSame(bounds.startTime) && endTime.isSame(bounds.endTime)) {
      this.setState({ isAllDay: true });
    }
  };

  updateBooking() {
    const booking = getBookingData(this.props);
    const confirmations = getConfirmations(this.props, this.initialState);

    if (deepEqual(this.initialState, booking)) {
      this.props.onClearAndClose();
      return Promise.resolve();
    }

    this.setState({ isSaving: true });
    return this.props.onUpdateBooking(booking)
      .then(() => {
        if (confirmations.sms || confirmations.email) {
          this.props.onSendConfirms(booking.id, confirmations);
        }
        this.props.onClearAndClose();
      })
      .catch(() => this.setState({ isSaving: false }));
  }

  addBooking() {
    this.setState({ isSaving: true });

    const booking = getBookingData(this.props);
    const confirmations = getConfirmations(this.props, this.initialState);

    this.props.onAddBooking({ ...booking })
      .then((newBooking) => {
        if (confirmations.sms || confirmations.email) {
          this.props.onSendConfirms({
            bookingId: newBooking.id,
            customerIds: newBooking.customers?.map(c => c.id),
            options: confirmations
          });
        }
        this.props.onClearAndClose();
      })
      .catch(() => this.setState({ isSaving: false }));
  }

  editSchedule() {
    this.setState({ isSaving: true });

    const schedule = getScheduleData(this.props);

    this.props.onEditSchedule(schedule)
      .catch(() => this.setState({ isSaving: false }));
  }

  handleSave = (ev) => {
    ev.preventDefault();

    const validationErrors = validateBooking(this.props);
    if (validationErrors) {
      this.setState({ validationErrors });
      return;
    }

    if (isScheduleException(this.props)) {
      return this.editSchedule();
    }
    if (isBookingNew(this.props)) {
      return this.addBooking();
    }
    return this.updateBooking();
  };

  handleClose = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    this.props.onClose();
  };

  handleKeyDown = (ev) => {
    if (ev.keyCode === 27) {
      if (this.props.showModal || this.props.customerFormVisible) {
        return;
      }
      if (this.state.showOptions) {
        this.setState({ showOptions: false });
      } else {
        this.handleClose(ev);
      }
    }
  };

  handleServiceSelect = (service, closeSearch = true) => {
    const { afterTime, serviceDuration } = service;
    const { id, startTime, endTime } = this.props;
    const newEndTime = serviceDuration
      ? moment(startTime).add(serviceDuration + (afterTime || 0), 'm')
      : endTime;

    const newTime = {
      startTime,
      endTime: newEndTime
    };

    this.props.onServiceSelect(id, service, newTime);

    if (closeSearch) {
      this.setState({ searchService: false });
    }
  };

  handleServiceAdd = (service) => {
    const { services } = this.props;
    const newServices = services ? [...services.values()] : [];
    newServices.push(service);

    this.props.onServiceAdd(service);
    updateTimeAndPriceFromServices(newServices, this.props);
    updateResourcesFromServices(newServices, this.props);
  };

  handleServiceRemove = (service) => {
    const { services } = this.props;
    const newServices = [...services.delete(service.id).values()];

    this.props.onServiceRemove(service);
    updateTimeAndPriceFromServices(newServices, this.props);
  };

  handleServiceUpdate = (service) => {
    const { services } = this.props;
    const newServices = [...services.set(service.id, service).values()];

    this.props.onServiceUpdate(service);
    updateTimeAndPriceFromServices(newServices, this.props);
  };

  handleResourceAdd = (resource) => {
    this.props.onResourceAdd(resource);
  };

  handleResourceRemove = (resource) => {
    this.props.onResourceRemove(resource);
  };

  handleCustomerSelect = ({ customer, company, vehicle }) => {
    if (customer) {
      this.props.onCustomerSelect(customer);

      if (!isBookingNew(this.props)) {
        this.props.addBookingCustomer(this.props.id, customer);
      }
    }
    if (company) {
      this.props.onCompanySelect(company);
    }
    if (vehicle) {
      this.props.onVehicleSelect(vehicle);
    }
    this.setState({ searchCustomer: false });
  };

  handleClearService = () => {
    const { startTime, endTime } = this.props;
    const serviceDuration = endTime.diff(startTime, 'minutes');

    this.handleServiceSelect({
      afterTime: 0,
      serviceDuration
    }, false);
  };

  handleClearVehicle = () => {
    this.props.onVehicleSelect(null);
  };

  handleClearCompany = () => {
    this.props.onCompanySelect(null);
  };

  handleClearCustomer = () => {
    const { id, customer } = this.props;
    if (!isBookingNew(this.props) && customer) {
      this.props.removeBookingCustomer(id, customer.customerId);
    }
    this.props.onCustomerSelect(null);
  };

  handleVehicleSearchStart = () => {
    this.setState({
      searchCustomer: true,
      searchCustomerValue: '',
      searchCustomerScope: ['Vehicle'],
      bodyHeight: this.getBodyHeight()
    });
  };

  handleCompanySearchStart = () => {
    this.setState({
      searchCustomer: true,
      searchCustomerValue: '',
      searchCustomerScope: ['Company'],
      bodyHeight: this.getBodyHeight()
    });
  };

  handleCustomerSearchStart = (value) => {
    const {
      company, customer, vehicle, enableCompanyBooking, enableVehicleBooking
    } = this.props;

    const searchCustomerScope = [];
    if (!customer) {
      searchCustomerScope.push('Customer');
    }
    if (enableCompanyBooking && !company) {
      searchCustomerScope.push('Company');
    }
    if (enableVehicleBooking && !vehicle) {
      searchCustomerScope.push('Vehicle');
    }

    this.setState({
      searchCustomer: true,
      searchCustomerValue: value,
      searchCustomerScope,
      bodyHeight: this.getBodyHeight()
    });
  };

  handleServiceSearchStart = () => {
    this.setState({ searchService: true, bodyHeight: this.getBodyHeight() });
  };

  handleResourceSearchStart = () => {
    this.setState({ searchResource: true, bodyHeight: this.getBodyHeight() });
  };

  handleEditNotes = () => {
    this.setState({ editNotes: true });
  };

  handleFetchSale = (id) => {
    return this.props.fetchBookingSale(id);
  };

  handleFetchReminders = (id) => {
    return this.props.fetchBookingReminders(id);
  };
}

const mapStateToProps = (state) => {
  const {
    bkf, mainViewState, gridViewState, locationFeatures, resourcesById,
    resourceServices, schedulesByResource, cf: customerForm
  } = state;

  return {
    resourcesById,
    resourceServices,
    schedulesByResource,
    type: bkf.get('type'),
    status: bkf.get('status'),
    sales: bkf.get('sales'),
    service: bkf.get('service'),
    services: bkf.get('services'),
    vehicle: bkf.get('vehicle'),
    company: bkf.get('company'),
    customer: bkf.get('customer'),
    startTime: bkf.get('startTime'),
    endTime: bkf.get('endTime'),
    resources: bkf.get('resources'),
    resourceId: bkf.get('resourceId'),
    attributes: bkf.get('attributes'),
    reservationType: bkf.get('reservationType'),
    exceptionType: bkf.get('exceptionType'),
    openType: bkf.get('openType'),
    customFields: bkf.get('customFields'),
    customValues: bkf.get('customValues'),
    customerForm: getFormValues('bkf-customer')(state),
    serviceForm: getFormValues('bkf-service')(state),
    priceForm: getFormValues('bkf-price')(state),
    timeForm: getFormValues('bkf-time')(state),
    addressForm: getFormValues('bkf-address')(state),
    notesForm: getFormValues('bkf-notes')(state),
    vehicleForm: getFormValues('bkf-vehicle')(state),
    companyForm: getFormValues('bkf-company')(state),
    customFieldsForm: getFormValues('bkf-custom-fields')(state),
    confirmationForm: getFormValues('bkf-confirmation')(state),
    classInfoForm: getFormValues('bkf-class-info')(state),
    deviceType: mainViewState.get('deviceType'),
    deviceOs: mainViewState.get('deviceOs'),
    externalKeyboard: gridViewState.get('externalKeyboard'),
    gridClientHeight: gridViewState.get('gridClientHeight'),
    scheduleEditMode: gridViewState.get('scheduleEditMode'),
    enableCompanyBooking: locationFeatures.get('EnableCompanyBooking'),
    enableVehicleBooking: locationFeatures.get('EnableVehicleBooking'),
    enablePos: locationFeatures.get('EnablePOS'),
    showPos: locationFeatures.get('ShowPOS'),
    embeddedInExternalPos: isEmbeddedInExternalPos(state),
    customerFormVisible: customerForm.get('formVisible'),
    showModal: bkf.get('showModal')
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTypeChange: (type) => {
      dispatch(setBKFProp('type', type));
      dispatch(setBKFCustomer(null));
      dispatch(setBKFService(null));
      dispatch(bookingTypeChanged({ bookingId: NEW_BOOKING, type }));
    },
    onAddBooking: (booking) => {
      return dispatch(addBooking(booking))
        .then((newBooking) => {
          dispatch(removeTempBooking(NEW_BOOKING));
          dispatch(clearAndCloseBKF());
          return newBooking;
        });
    },
    onUpdateBooking: (booking) => dispatch(updateBooking(booking)),
    onDeleteBooking: (bkId) => dispatch(deleteBooking(bkId)),
    onEditSchedule: (schedule) => {
      return dispatch(editSchedule(schedule))
        .then(() => dispatch(removeTempBooking(NEW_BOOKING)))
        .then(() => dispatch(clearAndCloseBKF()));
    },
    onClearAndClose: () => dispatch(clearAndCloseBKF()),
    onSendConfirms: (data) => dispatch(sendBookingConfirmation(data)),
    onSetBookingTime: (id, newTime) => dispatch(bookingMoved({ id, ...newTime })),
    onServiceSelect: (id, service, newTime) => {
      dispatch(setBKFBookingTime({ id, ...newTime }));
      dispatch(bookingMoved({ id, ...newTime }));
      dispatch(setBKFService(service));
    },
    onServiceAdd: (service) => dispatch(addBKFService(service)),
    onServiceRemove: (service) => dispatch(removeBKFService(service)),
    onServiceUpdate: (service) => dispatch(updateBKFService(service)),
    onResourceAdd: (resource) => dispatch(addBKFResource(resource)),
    onResourceRemove: (resource) => dispatch(removeBKFResource(resource)),
    onResetNewResource: (resource) => dispatch(resetBKFNewResource(resource)),
    onVehicleSelect: (vehicle) => dispatch(setBKFVehicle(vehicle)),
    onCompanySelect: (company) => dispatch(setBKFCompany(company)),
    onCustomerSelect: (customer) => dispatch(setBKFCustomer(customer)),
    addBookingCustomer: (id, customer) => dispatch(addBookingCustomer(id, customer)),
    removeBookingCustomer: (id, customerId) => dispatch(removeBookingCustomer(id, customerId)),
    queueBookingPayment: (bookingId) => dispatch(queueBookingPayment(bookingId)),
    fetchBookingSale: (bookingId) => dispatch(fetchBookingSale(bookingId)),
    fetchBookingReminders: (bookingId) => dispatch(fetchBookingReminders(bookingId))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(BookingForm);
