import PhoneUtil from '@Utils/phone-util';
import {
  fetchPost, checkStatus, prefixUrl, fetchErrorHandler
} from '@Utils/ajax-util';
import { calendar } from '@Utils/preference-keys';
import { postWebkitMessage } from '@Utils/wk-embed-bridges';
import { resourceFromColIdx } from '@Components/calendar/grid/grid-state-helper';
import { bookingDeleted, bookingAdded, setUndoableBooking, getUndoState } from './booking-actions';
import { getResourcesInView } from './calendar-selectors';

export const ADD_BOOKING_TO_CLIPBOARD = 'ADD_BOOKING_TO_CLIPBOARD';
export const REMOVE_BOOKING_FROM_CLIPBOARD = 'REMOVE_BOOKING_FROM_CLIPBOARD';
export const PASTE_BOOKING = 'PASTE_BOOKING';
export const ADD_CLIPBOARD_DRAGGER = 'ADD_CLIPBOARD_DRAGGER';
export const REMOVE_CLIPBOARD_DRAGGER = 'REMOVE_CLIPBOARD_DRAGGER';

export function addClipboardDragger(dragger) {
  return (dispatch, getState) => {
    dispatch({
      type: ADD_CLIPBOARD_DRAGGER,
      dragger
    });
  };
}

export function removeClipboardDragger() {
  return {
    type: REMOVE_CLIPBOARD_DRAGGER
  };
}

export function addToClipboard(id, copy, sourceResourceId) {
  return (dispatch, getState) => {
    const booking = getState().bookingsById.get(id);

    // If we're making a copy, then we reset a couple of fields that are unlikely to make sense to copy across
    //
    const changes = copy ? {
      copyOnPaste: true,
      channel: 'Cal',
      dropIn: false,
      askedForPerson: false,
      status: 'Booked',
      payment: null,
      sales: null,
      reminders: null,
      review: null,
      saleId: null,
      viaClipboard: true,
      sourceResourceId
    } : {
      copyOnPaste: false,
      viaClipboard: true,
      sourceResourceId
    };

    dispatch({
      type: ADD_BOOKING_TO_CLIPBOARD,
      booking: { ...booking, ...changes }
    });
  };
}

export function pasteBooking(bk, routeParams) {
  return (dispatch, getState) => {
    const state = getState();
    const { bookingsById } = state;

    const resource = resourceFromColIdx(state, routeParams, bk.colIdx);

    const booking = {
      ...bk,
      resourceId: resource?.id || bk.sourceResourceId,
      sourceResourceId: bk.sourceResourceId,
      pendingMove: false
    };

    dispatch(bookingDeleted('DRAGGER', 'local'));

    if (booking.copyOnPaste) {
      dispatch(copyPastedBooking(booking));
    } else {
      const undoState = getUndoState(bookingsById.get(bk.id), {
        sourceResourceId: booking.sourceResourceId,
        targetResourceId: booking.resourceId
      });
      dispatch(movePastedBooking(booking, undoState));
    }
  };
}

export function addPasteDragger(dragger, routeParams) {
  return (dispatch, getState) => {
    const state = getState();

    // Add the first resourceId from current view to booking if dragger doesn't have
    // any resources in current view, otherwise it will be filtered out.
    const resourceIdsInView = getResourcesInView(state, { routeParams }).map(r => r.id);
    const resourceId = dragger.resources.every(r => !resourceIdsInView.includes(r.id))
      ? resourceIdsInView[0]
      : null;

    const booking = {
      ...dragger,
      id: 'DRAGGER',
      resourceId: resourceId || dragger.resourceId,
      pasteDragger: true
    };

    dispatch(bookingDeleted('DRAGGER', 'local'));
    dispatch(bookingAdded(booking));
  };
}

export function removePasteDragger() {
  return bookingDeleted('DRAGGER', 'local');
}

export function cancelCopyPaste(bookingId) {
  return (dispatch, getState) => {
    dispatch(bookingDeleted('DRAGGER', 'local'));
    dispatch(removeFromClipboard(bookingId));
  };
}

function notifyEmptyClipboard() {
  postWebkitMessage('clipboardEmpty', { foo: 'bar' });
}

export function removeFromClipboard(bookingId) {
  return (dispatch, getState) => {
    const { bookingsClipboard } = getState();

    if (bookingsClipboard.size <= 1) {
      notifyEmptyClipboard();
    }

    dispatch({
      type: REMOVE_BOOKING_FROM_CLIPBOARD,
      bookingId
    });
  };
}

function movePastedBooking(booking, undoState) {
  const url = prefixUrl(`/bookings/${booking.id}/move`);

  return (dispatch, getState) => {
    const { locationConfig } = getState();
    const isUndoable = !locationConfig.get(calendar.confirmMoveEnabled);

    dispatch(bookingAdded(booking), 'local');
    dispatch(removeFromClipboard(booking.id));

    const confirmations = isUndoable ? {} : {
      sendSmsConfirmation: booking.sendSmsConfirmation,
      sendEmailConfirmation: booking.sendEmailConfirmation
    };
    const body = {
      startTime: booking.startTime.toISOString(),
      endTime: booking.endTime.toISOString(),
      sourceResourceId: booking.sourceResourceId,
      targetResourceId: booking.resourceId,
      ...confirmations
    };

    return fetch(url, fetchPost(body))
      .then(res => dispatch(checkStatus(res)))
      .then(req => req.json())
      .then(json => dispatch(bookingPasted({ ...booking, ...json })))
      .then(req => dispatch(bookingDeleted('DRAGGER', 'local')))
      .then(req => dispatch(setUndoableBooking(isUndoable ? undoState : null)))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function getBookingToCopy(booking) {
  return {
    ...booking,
    secondaryResources: booking.resources
      .filter(r => !r.primary && r.id !== booking.resourceId).map(r => r.id),
    channel: 'Cal',
    dropIn: false,
    askedForPerson: false,
    copyOnPaste: false
  };
}

function copyPastedBooking(booking) {
  const url = prefixUrl(`/bookings/resource/${booking.resourceId}/`);

  return (dispatch) => {
    dispatch(removeFromClipboard(booking.id));
    const add = getBookingToCopy(booking);

    return fetch(url, fetchPost(add))
      .then(res => dispatch(checkStatus(res)))
      .then(req => req.json())
      .then(json => dispatch(bookingAdded({ ...booking, ...json }, 'local')))
      .then(req => dispatch(bookingDeleted('DRAGGER', 'local')))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function bookingPasted(booking) {
  return {
    type: PASTE_BOOKING,
    booking
  };
}
