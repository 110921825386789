import axios from 'axios';
import {
  fetchGet, fetchPost, fetchPut, checkStatus, prefixUrl, logError, fetchErrorHandler,
  axiosDefault, checkStatusAxios, axiosErrorHandler
} from '@Utils/ajax-util';
import { authenticate } from '@Login/actions';
import { networkFailure } from './network-actions';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const SET_PASSWORD_POLICY = 'SET_PASSWORD_POLICY';
export const SET_CLIENT_PREF = 'SET_CLIENT_PREF';

function changePasswordProgress() {
  return {
    type: CHANGE_PASSWORD,
    state: {
      isChangingPassword: true,
      wrongPassword: false,
      changePasswordSuccess: false
    }
  };
}

function changePasswordSuccess() {
  return {
    type: CHANGE_PASSWORD,
    state: {
      isChangingPassword: false,
      wrongPassword: false,
      changePasswordSuccess: true
    }
  };
}

function changePasswordWrongPassword() {
  return {
    type: CHANGE_PASSWORD,
    state: {
      isChangingPassword: false,
      wrongPassword: true,
      changePasswordSuccess: false
    }
  };
}

function fetchPasswordPolicySuccess(passwordPolicy) {
  return {
    type: SET_PASSWORD_POLICY,
    passwordPolicy
  };
}

export function setUserPreferences(prefs) {
  return {
    type: SET_CLIENT_PREF,
    state: prefs
  };
}

export function setUserPreference(forLocation, prefs) {
  return (dispatch, getState) => {
    const { userClientPreferences } = getState();

    const state = {};
    const _org = sessionStorage.getItem('org');
    const _loc = sessionStorage.getItem('loc');

    if (forLocation) {
      const locKey = {},
        key = `${_org}/${_loc}`;
      locKey[key] = prefs;
      state.location = locKey;
    } else {
      state.user = prefs;
    }
    const newPrefs = userClientPreferences.mergeDeep(state);

    dispatch({
      type: SET_CLIENT_PREF,
      state
    });

    const url = prefixUrl('/account/client-prefs/');
    return fetch(url, fetchPut(newPrefs.toJS()))
      .then(res => dispatch(checkStatus(res)))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function changePassword(request) {
  const url = prefixUrl('/account/chpasswd');
  return (dispatch, getState) => {
    const authRequest = {
      username: getState().authState.get('username'),
      password: request.new
    };
    dispatch(changePasswordProgress());
    return fetch(url, fetchPost(request))
      .then((response) => {
        if (!response.ok) {
          const error = new Error();
          error.status = response.status;
          error.response = response;
          throw error;
        }
      })
      .then(() => dispatch(authenticate(authRequest)))
      .then(() => dispatch(changePasswordSuccess()))
      .catch((error) => {
        if (error.status === 401) {
          dispatch(changePasswordWrongPassword());
        } else {
          logError(error);
          dispatch(networkFailure());
        }
      });
  };
}

export function setPassword(userId, password) {
  const url = prefixUrl(`/users/${userId}/set-password`);
  return (dispatch) => {
    return fetch(url, fetchPost({ password }))
      .then(res => dispatch(checkStatus(res)));
  };
}

export function fetchPasswordPolicy() {
  const url = prefixUrl('/account/minimum-pw-policy');
  return (dispatch) => {
    return fetch(url, fetchGet())
      .then(res => dispatch(checkStatus(res)))
      .then(req => req.json())
      .then(({ policy }) => dispatch(fetchPasswordPolicySuccess(policy)))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function fetchUserNotifications(loginId) {
  return (dispatch) => {
    const url = prefixUrl(`/user/${loginId}/notifications/preferences/`);
    const config = axiosDefault();

    return axios.get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function setUserNotifications(loginId, notifications) {
  return (dispatch) => {
    const url = prefixUrl(`/user/${loginId}/notifications/preferences/`);
    const config = axiosDefault();

    return axios.put(url, notifications, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}
