import React from 'react';
import { useDispatch } from 'react-redux';
import { formatPhoneNumber } from '@Utils/phone-util';
import { setCustomerFormState } from '@State/cf-actions';

const BookingClassCustomer = ({ customer, onOpen, selected, onSelect }) => {
  const dispatch = useDispatch();
  const { name, phoneNumber, otherPhoneNumber } = customer;
  const phoneNumbers = [phoneNumber, otherPhoneNumber].filter(p => p)
    .map(p => formatPhoneNumber(p));

  const onOpenCustomer = () => {
    dispatch(setCustomerFormState({
      formVisible: true,
      customer
    }));
  };

  return (
    <div className="booking-class-customer" onClick={onSelect}>
      <div className="buttons" onClick={ev => ev.stopPropagation()}>
        <button className="btn-label noshow" tabIndex={-1} onClick={onOpenCustomer}>Öppna</button>
        <button className="btn-label" tabIndex={-1} onClick={onOpen}>Show</button>
        <button className="btn-label show" tabIndex={-1} onClick={onOpen}>No show</button>
      </div>
      <div className="content">
        <aside><input type="checkbox" checked={selected} onChange={onSelect} /></aside>
        <section>
          <h4>{name}</h4>
          {phoneNumbers.length > 0 && (
            <div>
              {phoneNumbers.join(' / ')}
            </div>
          )}
        </section>
      </div>
    </div>
  );
};

export default BookingClassCustomer;
