import React, { useState } from 'react';
import ModalDialog from '@Components/dialogs/modal-dialog';
import { Button } from '@Components/ui/styled/main';

const ShowImageModal = ({ booking }) => {
  const [showImage, setShowImage] = useState(false);

  return (
    <>
      <Button tiny gray className="print-hidden" onClick={() => setShowImage(true)}>Visa bild</Button>
      {showImage && (
        <ModalDialog
          contentSize="xl"
          hideCloseButton
          closeOnClickOutside={false}
        >
          {booking.attributes.imageUrl && (
            <img className="ongoing-booking-image" src={booking.attributes.imageUrl} alt="" />
          )}
          <div className="mt4 text-center">
            <Button large gray onClick={() => setShowImage(false)}>
              Stäng
            </Button>
          </div>
        </ModalDialog>
      )}
    </>
  );
};

export default ShowImageModal;
