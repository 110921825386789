import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { sms, email } from '@Utils/preference-keys';
import { isBookingNew } from '@Utils/booking-util';
import PhoneUtil from '@Utils/phone-util';
import { getSmsEnabled } from '@State/selectors';
import { txt } from '@Utils/i18n-util';
import msg from './booking-confirmation.msg';

class BookingConfirmation extends Component {
  render() {
    const {
      id, emailAddress, phoneNumber, smsEnabled
    } = this.props;
    const sendSmsEnabled = smsEnabled && phoneNumber;

    return (
      <div className="booking-form-block confirmations">
        <p>
          {isBookingNew(this.props)
            ? <strong>{txt(msg.txtExistingBookingChanged)}</strong>
            : <strong>{txt(msg.txtNewBookingChanged)}</strong>}
        </p>
        <div className="form-group">
          <div className={sendSmsEnabled ? 'checkbox' : 'checkbox disabled'}>
            <label>
              <Field name="sendSmsConfirmation" component="input" type="checkbox" value="true" />
              {sendSmsEnabled ? txt(msg.lblSendSms, { mobile: PhoneUtil.formatPhoneNumber(phoneNumber) }) : txt(msg.lblSendSmsNotAvailable)}
            </label>
          </div>
          <div className={emailAddress ? 'checkbox' : 'checkbox disabled'}>
            <label>
              <Field name="sendEmailConfirmation" component="input" type="checkbox" value="true" />
              {emailAddress ? txt(msg.lblSendEmail, { email: emailAddress }) : txt(msg.lblSendEmailNotAvailable)}
            </label>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { bkf, locationConfig } = state;
  const customer = bkf.get('customer');
  const customerForm = getFormValues('bkf-customer')(state);
  const { phoneNumber, email: emailAddress } = customerForm || customer || {};
  const smsEnabled = getSmsEnabled(state);

  const initialValues = {
    sendSmsConfirmation: phoneNumber && locationConfig.get(sms.defaultOptionSmsConfirm) && smsEnabled,
    sendEmailConfirmation: emailAddress && locationConfig.get(email.defaultOptionEmailConfirm)
  };

  return {
    initialValues,
    id: bkf.get('id'),
    phoneNumber,
    emailAddress,
    smsEnabled
  };
};

export default connect(mapStateToProps)(reduxForm({
  form: 'bkf-confirmation',
  destroyOnUnmount: false
})(BookingConfirmation));
