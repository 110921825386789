import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRouteParams } from '@State/selectors';
import { fetchViewData } from '@State/view-actions';
import NoContent from '@Components/ui/no-content';
import FilterContainer from '@Components/ui/filter-container';
import BookingModals from '@Components/calendar/booking/booking-modals';
import { useReloadOnWake } from './useReloadOnWake';
import { useCurrentTime } from './useCurrentTime';
import { matchFilter } from './ongoing-helpers';
import OngoingBooking from './ongoing-booking';
import OngoingList from './ongoing-list';

const Ongoing = (props) => {
  const dispatch = useDispatch();
  const [filter, setFilter] = useState('');
  const [status, setStatus] = useState('Booked');
  const routeParams = useSelector(state => getRouteParams(state, props));
  const groupId = useSelector(state => state.orderedGroups.first()?.get('id'));
  const bookingsById = useSelector(state => state.bookingsById);

  const bookingsByHour = useMemo(() => bookingsById.sortBy(b => b.startTime)
    .filter((b) => {
      return b.type === 'SimpleBooking' && b.status !== 'Cancelled' && !b.pending
        && b.customerId && (!status || b.status === status)
        && (!filter || matchFilter(b, filter.toLowerCase()));
    })
    .groupBy(b => b.startTime.format('HH')), [bookingsById, status, filter]);

  const reloadViewData = () => {
    if (groupId) {
      dispatch(fetchViewData({
        entityType: 'group',
        entityId: groupId,
        viewMode: 'day',
        viewDate: moment().format('YYYY-MM-DD')
      }));
    }
  };

  const time = useCurrentTime();
  useEffect(reloadViewData, [groupId]);
  useReloadOnWake(reloadViewData);

  if (routeParams.subsection === 'list') {
    return (
      <OngoingList bookingsById={bookingsById} />
    );
  }

  return (
    <div className="columns-wrapper">
      <div className="columns-container">
        <div className="columns-content">
          <div className="columns-content-container width-large">
            <div className="columns-content-body with-padding">
              <div className="hidden-print">
                <div className="report-buttons mt0">
                  <h4 className="text-muted mt0 mb0">
                    <i className="far fa-clock mr1" />
                    {time}
                  </h4>
                </div>
                <FilterContainer>
                  <div className="form-group">
                    <label>Bokningar</label>
                    <div className="select-container">
                      <select className="form-control" value={status} onChange={(ev) => setStatus(ev.target.value)}>
                        <option value="">Alla dagens bokningar</option>
                        <option value="Booked">Pågående och kommande</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Sök kund</label>
                    <input
                      type="text"
                      className="form-control"
                      value={filter}
                      placeholder="För- eller efternamn"
                      onChange={(ev) => setFilter(ev.target.value)}
                    />
                  </div>
                </FilterContainer>
              </div>
              {bookingsByHour.isEmpty() && (
                <NoContent icon="fa fa-play-circle">
                  Det finns inga bokningar för detta urval
                </NoContent>
              )}
              {bookingsByHour.entrySeq().map(([hour, bookings]) => {
                return (
                  <div key={hour}>
                    <h4 className="mt1 mb2 text-muted">{hour}:00</h4>
                    <div className="ongoing-grid">
                      {bookings.valueSeq().map(booking => {
                        return (
                          <OngoingBooking
                            key={booking.id}
                            booking={booking}
                            routeParams={routeParams}
                            time={time}
                          />
                        );
                      })}
                    </div>
                  </div>
                );
              })}
              <BookingModals routeParams={routeParams} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ongoing;
