import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import Popover from '@Components/ui/popover';
import { DialogButtonsHorizontal, DialogButton } from '@Components/dialogs/dialog-styles';
import Button from '@Components/ui/button';
import useKeyPress from '@Hooks/useKeyPress';
import { fetchResourceServices } from '@State/bkf/actions';

const OngoingExtendPopover = ({ booking, onSubmit }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showPopover, setShowPopover] = useState(false);
  const [serviceId, setServiceId] = useState(null);
  const resourceServices = useSelector(state => state.resourceServices.get(booking.resourceId));

  useEffect(() => {
    if (booking && showPopover) {
      dispatch(fetchResourceServices(booking.resourceId, true));
    }
  }, [booking, showPopover]);

  const handleClose = () => {
    setShowPopover(false);
    setServiceId(null);
  };

  useKeyPress(27, () => handleClose());
  useKeyPress(13, () => handleSubmit());

  const handleSubmit = () => {
    setLoading(true);
    const service = resourceServices.find(s => s.id === parseInt(serviceId));
    return onSubmit(service)
      .finally(() => {
        setLoading(false);
        setShowPopover(false);
      });
  };

  const getBody = () => {
    return (
      <div className="Popover-dialog-sm">
        <div className="form-group">
          <label className="form-label">Förläng bokning</label>
          <div className="select-container">
            <select className="form-control" value={serviceId} onChange={e => setServiceId(e.target.value)}>
              <option value="" style={{ display: 'none' }}>Välj tjänst</option>
              {resourceServices?.filter(s => s.addon).map(service => (
                <option key={service.id} value={service.id}>{service.name}</option>
              ))}
            </select>
          </div>
        </div>
        <DialogButtonsHorizontal>
          <DialogButton disabled={loading} small gray onClick={handleClose}>Avbryt</DialogButton>
          <DialogButton loading={loading} small primary disabled={!serviceId} onClick={handleSubmit}>Förläng</DialogButton>
        </DialogButtonsHorizontal>
      </div>
    );
  };

  return (
    <Popover
      isOpen={showPopover}
      preferPlace="row"
      className="Popover-big-radius"
      onOuterAction={handleClose}
      body={getBody()}
    >
      <Button tiny white onClick={() => setShowPopover(true)}>
        Förläng
      </Button>
    </Popover>
  );
};

export default OngoingExtendPopover;
