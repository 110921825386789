import { defineMessages } from 'react-intl';

export default defineMessages({

  lblDuration: {
    id: 'calendar.bkf.search-service.lblDuration',
    defaultMessage: '{duration} min'
  },
  lblDurationWithAfterTime: {
    id: 'calendar.bkf.search-service.lblDurationWithAfterTime',
    defaultMessage: '{duration} min + {afterTime} min pause'
  },
  lblPriceFrom: {
    id: 'calendar.bkf.search-service.lblPriceFrom',
    defaultMessage: 'Price from'
  },
  btnAddItem: {
    id: 'calendar.bkf.search-service.btnAddItem',
    defaultMessage: 'Add service'
  },
  placeholderAddItem: {
    id: 'calendar.bkf.search-service.placeholderAddItem',
    defaultMessage: 'Search or add service'
  },
  placeholderSearch: {
    id: 'calendar.bkf.search-service.placeholderSearch',
    defaultMessage: 'Search service'
  },
  btnBack: {
    id: 'calendar.bkf.search-service.btnBack',
    defaultMessage: 'Back'
  },
  btnClear: {
    id: 'calendar.bkf.search-service.btnClear',
    defaultMessage: 'Clear'
  },
  lblTitle: {
    id: 'calendar.bkf.search-service.lblTitle',
    defaultMessage: 'Select services'
  }
});
