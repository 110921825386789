import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CustomerIdContext from '@Components/customers/customer-id-context';
import Modal from '@Components/ui/modal';
import CustomerModalContent from '@Components/customers/customer-modal/customer-modal-content';
import { setCustomerFormState, openCustomerTab } from '@State/cf-actions';
import useKeyPress from '@Hooks/useKeyPress';
import { customerClearForm } from '@State/customer-actions';

const CustomerModal = ({ routeParams }) => {
  const dispatch = useDispatch();

  const formVisible = useSelector(({ cf }) => cf.get('formVisible'));
  const customer = useSelector(({ cf }) => cf.get('customer'));
  const bkfModal = useSelector(({ bkf }) => bkf.get('showModal'));

  const onCloseForm = () => {
    dispatch(setCustomerFormState({ formVisible: false }));
    dispatch(customerClearForm());
    dispatch(openCustomerTab({ tab: 'overview' })); // set to default tab
  };

  useKeyPress(27, () => {
    if (!bkfModal && formVisible) {
      onCloseForm();
    }
  }, [bkfModal, formVisible]);

  const customerId = customer?.customerId || customer?.id || '';

  if (!formVisible) return null;

  return (
    <Modal
      titleText="text"
      focusTrapPaused
      focusDialog
      underlayClickExits={false}
      mounted
      dialogClass="modal-dialog customer-modal-form"
    >
      <div className="modal-content">
        <CustomerIdContext.Provider value={customerId}>
          <CustomerModalContent
            routeParams={routeParams}
            customer={customer}
            onCloseForm={onCloseForm}
          />
        </CustomerIdContext.Provider>
      </div>
    </Modal>
  );
};

export default CustomerModal;
