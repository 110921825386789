export const NETWORK_FAILED = 'NETWORK_FAILED';
export const VERSION_MISMATCH = 'VERSION_MISMATCH';

export function networkFailure(message, title, details) {
  return {
    type: NETWORK_FAILED,
    title: title || 'Ett fel inträffade',
    message: message || 'Ett fel inträffade vid kontakt med servern. Försök igen eller kontakta oss om felet kvarstår.',
    details
  };
}

export function resetNetworkFailure() {
  return {
    type: NETWORK_FAILED,
    message: null
  };
}

export function versionMismatch(currentVersion, requiredVersion) {
  return {
    type: VERSION_MISMATCH,
    state: {
      currentVersion,
      requiredVersion
    }
  };
}
