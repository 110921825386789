import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import Autosuggest from 'react-autosuggest';
import Highlighter from 'react-highlight-words';
import { classes, escapeRegexCharacters } from '@Components/ui/utils';
import { getResourcesForBookingForm } from '@State/calendar-selectors';

import { txt } from '@Utils/i18n-util';
import Bouncefix from './bouncefix';
import msg from './search-resource.msg';

class SearchResource extends Component {
  static propTypes = {
    primaryResourceId: PropTypes.number.isRequired,
    orderedResources: PropTypes.array.isRequired,
    onAdd: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    deviceType: PropTypes.string.isRequired,
    deviceOs: PropTypes.string.isRequired,
    externalKeyboard: PropTypes.bool.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      value: '',
      suggestions: this.getResources(props),
      isLoading: false,
      inputFocused: true
    };
  }

  componentDidMount() {
    const input = ReactDOM.findDOMNode(this.searchField);
    if (input) input.focus();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.orderedResources !== nextProps.orderedResources) {
      this.setState({
        suggestions: this.getSuggestions('', this.getResources(nextProps))
      });
    }
  }

  getResources = (props) => props.orderedResources;

  getSuggestions(value, resources) {
    const escapedValue = escapeRegexCharacters(value).trim();
    if (!value || value.trim().length === 0) {
      return resources;
    }
    if (escapedValue.length === 0) {
      return [];
    }

    const regex = new RegExp(`${escapedValue}`, 'i');
    const filteredResources = resources.filter(r => regex.test(r.name));
    return [{ name: value }, ...filteredResources];
  }

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: this.getSuggestions('', this.getResources(this.props))
    });
  };

  onSuggestionsFetchRequested = ({ value, reason }) => {
    if (reason === 'suggestion-selected') {
      return;
    }

    this.setState({
      suggestions: this.getSuggestions(value, this.getResources(this.props))
    });
  };

  onSuggestionSelected = (event, { suggestion }) => {
    event.preventDefault();
    event.stopPropagation();
    this.selectSuggestion(suggestion);
  };

  onSuggestionHighlighted = ({ suggestion }) => {
    this.highlightedSuggestion = suggestion;
  };

  onChange = (event, { newValue, method }) => {
    this.setState({ value: newValue });
  };

  handleKeyDown = (ev) => {
    if (ev.keyCode === 9 || ev.keyCode === 13) {
      ev.preventDefault();
      this.selectSuggestion(this.highlightedSuggestion);
    }
  };

  handleCancel = (ev) => {
    ev.preventDefault();
    this.props.onCancel();
  };

  selectSuggestion = (suggestion) => {
    if (suggestion && suggestion.id) {
      const { selectedResources, primaryResourceId } = this.props;
      const selected = selectedResources && selectedResources.has(suggestion.id);

      if (suggestion.id === primaryResourceId) {
        return;
      }

      if (selected) {
        this.props.onRemove(suggestion);
      } else {
        this.props.onAdd(suggestion);
      }
    }
  };

  renderInputComponent = (inputProps) => {
    return (
      <input
        {...inputProps}
        className="form-control"
        onFocus={(ev) => { inputProps.onFocus(ev); this.setState({ inputFocused: true }); }}
        /* setTimeout required on iOS, otherwise the 'select' event is ignored */
        onBlur={() => { setTimeout(() => { this.setState({ inputFocused: false }); }, 10); }}
        /* Important! Dont remove inputProps.ref call, subtle bug will be happening... */
        ref={(ref) => { inputProps.ref(ref); this.searchField = ref; }}
      />
    );
  };

  renderResource = (suggestion) => {
    const search = [this.state.value];

    return (
      <div>
        <strong>
          <Highlighter searchWords={search} textToHighlight={suggestion.name} autoEscape />
        </strong>
      </div>
    );
  };

  renderToggle = (selected) => {
    return (
      <div className={selected ? 'toggle selected' : 'toggle'}>
        <i className="fa fa-check" />
      </div>
    );
  };

  renderSuggestion = (suggestion) => {
    const { selectedResources, primaryResourceId } = this.props;
    const selected = selectedResources && selectedResources.has(suggestion.id);
    const className = classes({
      'booking-form-suggestion': true,
      disabled: suggestion.id === primaryResourceId,
      selected
    });

    return suggestion.id && (
      <div className={className}>
        {this.renderResource(suggestion)}
        {this.renderToggle(selected)}
      </div>
    );
  };

  render() {
    const { height, maxHeight, disabled } = this.props;
    const { value, suggestions } = this.state;
    const iosWithOSKOpen = this.props.deviceOs === 'iOS' && !this.props.externalKeyboard && this.state.inputFocused !== false;

    const inputProps = {
      placeholder: txt(msg.placeholderAddItem),
      onKeyDown: this.handleKeyDown,
      onChange: this.onChange,
      value
    };

    const theme = {
      suggestionsList: 'booking-form-suggestions',
      suggestionHighlighted: 'highlighted',
      suggestionsContainer: iosWithOSKOpen ? 'booking-form-suggestions-container-ios' : 'booking-form-suggestions-container'
    };

    const styleBookingForm = { height, maxHeight: maxHeight - 50 };

    return (
      <div className="booking-form">
        <div className="booking-form-header">
          <div className="cancel">
            <a href="#" onClick={this.handleCancel}>
              <i className="fa fa-chevron-left" /> {txt(msg.btnBack)}
            </a>
          </div>
          <h4 className="title">
            {txt(msg.lblTitle)}
          </h4>
          <div className="save" />
        </div>

        <Bouncefix className={disabled ? 'booking-form-body disabled' : 'booking-form-body'} style={styleBookingForm}>
          <Autosuggest
            theme={theme}
            suggestions={suggestions}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            onSuggestionHighlighted={this.onSuggestionHighlighted}
            onSuggestionSelected={this.onSuggestionSelected}
            renderInputComponent={this.renderInputComponent}
            renderSuggestion={this.renderSuggestion}
            getSuggestionValue={() => value}
            alwaysRenderSuggestions
            shouldRenderSuggestions={() => true}
            focusInputOnSuggestionClick={false}
            inputProps={inputProps}
          />
        </Bouncefix>
      </div>
    );
  }
}

const mapStateToProps = (state, { context }) => {
  const { bkf, findTime } = state;
  const isBooking = context === 'Booking';

  return {
    id: bkf.get('id'),
    orderedResources: getResourcesForBookingForm(state),
    selectedResources: isBooking
      ? bkf.get('resources')
      : findTime.get('resources')
  };
};

export default connect(mapStateToProps)(SearchResource);
