import React, { useContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import customerIdContext from '@Components/customers/customer-id-context';
import { fetchCustomerSales } from '@State/customer-actions';
import CustomerDetailInvoice from '@Components/customers/customer-detail/sales/customer-detail-invoice';
import CustomerDetailReceipt from '@Components/customers/customer-detail/sales/customer-detail-receipt';
import CustomerDetailSalesList from '@Components/customers/customer-detail/sales/customer-detail-sales-list';
import { Row, Text } from '@Components/ui/styled/main';
import {
  ScrollContent, ScrollWrapper, ColumnLeft, ColumnRight, HeaderTextEmpty
} from '@Components/customers/customer-styles';
import { sortEventByTimeAndReverse, groupEventsByMonth } from '@Utils/customer-util';
import CustomerEmptyTab from '@Components/customers/customer-detail/customer-empty-tab';
import Loader from '@Components/ui/loader';
import { getCustomerSalesUrl } from '@Utils/navigate';
import { getFeatures } from '@State/selectors';

const CustomerDetailSales = ({ routeParams, onSelectSales, ...props }) => {
  const dispatch = useDispatch();
  const tabId = props.tabId || routeParams?.tabId;
  const customerId = +useContext(customerIdContext);

  const { EnablePOS } = useSelector(getFeatures);
  const phoneMode = useSelector(state => state.mainViewState.get('phoneMode'));
  const salesStore = useSelector((state) => state.customerSales);
  const sales = salesStore.get('sales');
  const loading = salesStore.get('loading');
  const groupedSales = groupEventsByMonth(sales);

  const selectedSale = useMemo(() => {
    if (sales.length === 0) return null;
    if (tabId) {
      const saleById = sales.find((sale) => sale.id === +tabId);
      if (saleById) return (saleById);
    } else {
      const firstSale = sortEventByTimeAndReverse(groupedSales[0][1])[0]; // sort it and take first
      if (firstSale) return (firstSale);
    }
  }, [sales, tabId]);

  useEffect(() => {
    if (EnablePOS) {
      dispatch(fetchCustomerSales(customerId));
    }
  }, [customerId]);

  const handleSelect = (tabId) => onSelectSales(tabId, 'sales');

  if (!EnablePOS) {
    return (
      <HeaderTextEmpty>
        <img src="/pos.svg" width="200" />
        <Text dark center fs="13" fontWeight={400}>
          Här kan du se dina kunders köphistorik om du använder Cliento Kassa.
          <br />
          <br />
          Kontakta support för en offert.
        </Text>
      </HeaderTextEmpty>
    );
  }

  if (sales && sales.length > 0 && selectedSale?.id && routeParams && !routeParams.tabId) {
    return <Redirect to={getCustomerSalesUrl(routeParams, selectedSale.id)} />;
  }

  if (loading) {
    return <Loader topPosition />;
  }

  if (!groupedSales || !selectedSale?.saleReceiptId) {
    return (
      <CustomerEmptyTab
        title="Inga köp"
        text="Det finns inga köp registrerade"
      />
    );
  }

  const renderList = () => (
    <CustomerDetailSalesList
      sales={sales}
      handleSelect={handleSelect}
      selectedSale={selectedSale}
    />
  );

  if (phoneMode) {
    return (
      <Row scrollContainer>
        <ScrollWrapper>
          <ScrollContent>
            {renderList()}
          </ScrollContent>
        </ScrollWrapper>
      </Row>
    );
  }

  return (
    <Row scrollContainer>
      <ColumnLeft>
        <ScrollWrapper>
          <ScrollContent>
            {renderList()}
          </ScrollContent>
        </ScrollWrapper>
      </ColumnLeft>
      <ColumnRight>
        <ScrollWrapper>
          <ScrollContent>
            {selectedSale?.saleReceiptType === 'Invoice' && selectedSale?.saleInvoiceId && (
              <CustomerDetailInvoice selectedSale={selectedSale} />
            )}
            {selectedSale?.saleReceiptType === 'Receipt' && selectedSale?.saleReceiptId && (
              <CustomerDetailReceipt selectedSale={selectedSale} />
            )}
          </ScrollContent>
        </ScrollWrapper>
      </ColumnRight>
    </Row>
  );
};
CustomerDetailSales.propTypes = {
  onSelectSales: PropTypes.func.isRequired
};

export default CustomerDetailSales;
