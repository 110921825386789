import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useKeyPress from '@Hooks/useKeyPress';
import { addBookingCustomer, fetchBookingReminders, toggleBKFModal } from '@State/bkf/actions';
import { bkfModals } from '@State/bkf/constants';
import { getClassStatus } from '@Utils/booking-util';
import Modal from '@Components/ui/modal';
import { cancelBooking } from '@State/booking-actions';
import { DialogTitleBtn } from '@Components/dialogs/dialog-styles';
import SearchCustomerModal from '@Components/pos/sale/search-customer-modal';
import SendSmsModal from '@Components/customers/customer-detail/send-sms/send-sms-modal';
import ConfirmPopover from '@Components/ui/confirm-popover';
import BookingClassCustomer from './booking-class-customer';
import BookingClassStatus from './booking-class-status';

const BookingClassModal = () => {
  const dispatch = useDispatch();
  const formVisible = useSelector(({ cf }) => cf.get('formVisible'));
  const bookingId = useSelector(state => state.bkf.get('id'));
  const services = useSelector(state => state.bkf.get('services'));
  const startTime = useSelector(state => state.bkf.get('startTime'));
  const maxSlots = useSelector(state => state.bkf.get('maxSlots'));
  const bookedSlots = useSelector(state => state.bkf.get('bookedSlots'));
  const customers = useSelector(state => state.bookingsById.get(bookingId).customers || []);
  const status = getClassStatus(bookedSlots, maxSlots);

  const [selected, setSelected] = useState([]);
  const [showSendSms, setShowSendSms] = useState(false);
  const [showAddCustomer, setShowAddCustomer] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const noneSelected = selected.length === 0;
  const allSelected = !noneSelected && selected.length === customers.length;

  useEffect(() => {
    dispatch(fetchBookingReminders(bookingId));

    document.body.classList.add('print-hidden');

    return () => {
      document.body.classList.remove('print-hidden');
    };
  }, []);

  const onSelect = (id) => {
    if (selected.includes(id)) {
      setSelected(selected.filter(s => s !== id));
    } else {
      setSelected([...selected, id]);
    }
  };

  const onSelectAll = () => {
    setSelected(allSelected ? [] : customers.map(c => c.id));
  };

  const onSendSms = () => setShowSendSms(true);
  const onCloseForm = () => dispatch(toggleBKFModal(bkfModals.class, false));

  const onPrint = (ev) => {
    ev.preventDefault();
    ev.target.blur();
    window.print();
  };

  const onAddCustomer = (ev) => {
    ev.preventDefault();
    ev.target.blur();
    setShowAddCustomer(true);
  };

  const onCustomerSelected = ({ customerId, ...customer }) => {
    dispatch(addBookingCustomer(bookingId, { id: customerId, ...customer }));
    setShowAddCustomer(false);
  };

  const onCancelCustomer = () => {
    return dispatch(cancelBooking({ bookingId, customerIds: selected }))
      .then(() => setShowConfirm(false));
  };

  useKeyPress(27, () => {
    if (!showSendSms) {
      onCloseForm();
    }
  });

  if (formVisible) {
    return null;
  }

  if (showSendSms) {
    return (
      <SendSmsModal
        customerId={customers[0].id}
        phoneNumber={customers[0].phoneNumber}
        onClose={() => setShowSendSms(false)}
      />
    );
  }

  if (showAddCustomer) {
    return (
      <SearchCustomerModal
        onSelected={onCustomerSelected}
        onCancel={() => setShowAddCustomer(false)}
        maxHeight={document.body.scrollHeight}
        height={400}
        scope={['Customer']}
      />
    );
  }

  return (
    <Modal
      titleText="Deltagarlista"
      focusTrapPaused
      focusDialog
      underlayClickExits={false}
      mounted
      dialogClass="modal-dialog booking-class-modal-dialog"
    >
      <div className="modal-content">
        <div className="modal-body">
          <div className="pull-right">
            <DialogTitleBtn onClick={onCloseForm}>Stäng</DialogTitleBtn>
          </div>
          <div className="print-visible">
            <h4 className="mt0 mb0">{services.map(s => s.name).join(', ')}</h4>
            <p className="text-muted">{startTime.format('LLLL')}</p>
          </div>
          <BookingClassStatus />
          <div className="booking-class-modal-content" tabIndex={0}>
            <div className="booking-class-customer-header">
              <h4 className="mt0 mb0">Deltagarlista</h4>
              <div className="options">
                <button type="button" className="btn btn-default" onClick={onAddCustomer}>
                  <i className="fa fa-user-plus mr1" />Lägg till
                </button>
                <button type="button" className="btn btn-default" onClick={onPrint}>
                  <i className="fa fa-print mr1" />Skriv ut
                </button>
              </div>
            </div>
            <div className="booking-class-customer-header">
              <button type="button" className="btn btn-default" onClick={onSelectAll}>
                <div className="select">
                  <input type="checkbox" checked={allSelected} />
                  Välj alla
                </div>
              </button>
              <div className="options">
                <button type="button" className="btn btn-default" onClick={onSendSms} disabled={noneSelected}>Skicka SMS</button>
                <ConfirmPopover
                  text="Vill du boka av markerade deltagare?"
                  confirmText="Boka av"
                  onClose={() => setShowConfirm(false)}
                  onConfirm={onCancelCustomer}
                  isOpen={showConfirm}
                >
                  <button type="button" className="btn btn-danger" disabled={noneSelected} onClick={() => setShowConfirm(true)}>
                    Boka av
                  </button>
                </ConfirmPopover>
              </div>
            </div>
            <div className="print-visible">
              {customers.map(customer => {
                return (
                  <BookingClassCustomer
                    key={customer.id}
                    customer={customer}
                    selected={selected.includes(customer.id)}
                    onSelect={() => onSelect(customer.id)}
                  />
                );
              })}
              {customers.length === 0 ? (
                <p>Inga deltagare</p>
              ) : null}
            </div>
          </div>
          <div className="text-right">
            <button type="button" className="btn btn-default" onClick={onCloseForm}>Stäng</button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default BookingClassModal;
