import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import Autosuggest from 'react-autosuggest';
import Highlighter from 'react-highlight-words';
import { getServiceColors, getServicesForResourcesInView } from '@State/calendar-selectors';

import { fetchResourceServices } from '@State/bkf/actions';
import { escapeRegexCharacters } from '@Components/ui/utils';

import { colors } from '@Components/ui/styled/variables';
import Label from '@Components/ui/label';
import CurrencyUtil from '@Utils/currency-util';
import { txt } from '@Utils/i18n-util';
import Bouncefix from './bouncefix';
import msg from './search-service.msg';

class SearchService extends Component {
  static propTypes = {
    resourceId: PropTypes.number,
    services: PropTypes.array.isRequired,
    onAdd: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    onSelected: PropTypes.func,
    onCancel: PropTypes.func.isRequired,
    deviceType: PropTypes.string.isRequired,
    deviceOs: PropTypes.string.isRequired,
    externalKeyboard: PropTypes.bool.isRequired,
    fetchResourceServices: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      value: '',
      suggestions: props.services,
      isLoading: false,
      inputFocused: true
    };
  }

  componentDidMount() {
    const input = ReactDOM.findDOMNode(this.searchField);
    if (input) input.focus();
    this.loadServicesIfRequired(this.props.resourceId);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.services !== nextProps.services) {
      this.setState({
        suggestions: this.getSuggestions('', nextProps.services)
      });
    }
  }

  loadServicesIfRequired(resourceId) {
    const lastLoaded = this.props.resourceServices.get('timestamp');
    const cacheExpired = lastLoaded && (new Date() - lastLoaded) > (1000 * 30); /* Expire the cache after 60 sec */

    // The cacheExpiry is only a safeguard so that the cache gets r eloaded eventually.
    // even if the computer has been in sleep mode while pusher events are sent
    // The cache is mainly used so that we dont issue multiple cache load everytime the BKF is updated
    // The grid will clear the cache on mount
    if (resourceId && (!this.props.resourceServices.has(resourceId) || cacheExpired)) {
      this.props.fetchResourceServices(resourceId, cacheExpired);
    }
  }

  getSuggestions(value, services) {
    const escapedValue = escapeRegexCharacters(value).trim();
    if (!value || value.trim().length === 0) {
      return services;
    }
    if (escapedValue.length === 0) {
      return [];
    }

    const regex = new RegExp(`${escapedValue}`, 'i');
    const filteredServices = services.filter(s => regex.test(s.name));
    return [{ name: value }, ...filteredServices];
  }

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: this.getSuggestions('', this.props.services)
    });
  };

  onSuggestionsFetchRequested = ({ value, reason }) => {
    if (reason === 'suggestion-selected') {
      return;
    }

    this.setState({
      suggestions: this.getSuggestions(value, this.props.services)
    });
  };

  onSuggestionSelected = (event, { suggestion }) => {
    event.preventDefault();
    event.stopPropagation();
    this.selectSuggestion(suggestion);
  };

  onSuggestionHighlighted = ({ suggestion }) => {
    this.highlightedSuggestion = suggestion;
  };

  onChange = (event, { newValue, method }) => {
    this.setState({ value: newValue });
  };

  handleKeyDown = (ev) => {
    if (ev.keyCode === 9 || ev.keyCode === 13) {
      ev.preventDefault();
      this.selectSuggestion(this.highlightedSuggestion);
    }
  };

  handleCancel = (ev) => {
    ev.preventDefault();
    this.props.onCancel();
  };

  handleClear= (ev) => {
    ev.preventDefault();
    this.props.onClear();
  };

  selectSuggestion = (suggestion) => {
    if (suggestion && suggestion.id) {
      const { selectedServices, allowMultiple } = this.props;
      const selected = selectedServices && selectedServices.has(suggestion.id);

      if (!allowMultiple) {
        this.props.onSelected(suggestion);
        return;
      }

      if (selected) {
        this.props.onRemove(suggestion);
      } else {
        this.props.onAdd(suggestion);
      }
    } else {
      this.createServiceFromSearch();
    }
  };

  createServiceFromSearch = () => {
    const { startTime, endTime } = this.props;
    const serviceDuration = endTime.diff(startTime, 'minutes');

    this.props.onSelected({
      price: 0,
      afterTime: 0,
      name: this.state.value,
      serviceDuration,
      isNew: true
    });
  };

  renderInputComponent = (inputProps) => {
    return (
      <input
        {...inputProps}
        className="form-control"
        onFocus={(ev) => { inputProps.onFocus(ev); this.setState({ inputFocused: true }); }}
        /* setTimeout required on iOS, otherwise the 'select' event is ignored */
        onBlur={() => { setTimeout(() => { this.setState({ inputFocused: false }); }, 10); }}
        /* Important! Dont remove inputProps.ref call, subtle bug will be happening... */
        ref={(ref) => { inputProps.ref(ref); this.searchField = ref; }}
      />
    );
  };

  renderService = (suggestion, selected) => {
    const search = [this.state.value];
    const afterTime = suggestion ? suggestion.afterTime : 0;
    const duration = suggestion ? suggestion.totalDuration - afterTime : 0;

    const durationStr = afterTime > 0 ? txt(msg.lblDurationWithAfterTime, { duration, afterTime }) : txt(msg.lblDuration, { duration });
    const priceStr = (suggestion.priceFrom ? `${txt(msg.lblPriceFrom)} ` : '') + CurrencyUtil.accountCurrency(suggestion.price, 0);
    const color = this.props.serviceColors[suggestion.id];

    return (
      <div>
        <strong>
          {color && <span className="color-dot" style={{ background: color }} />}
          <Highlighter searchWords={search} textToHighlight={suggestion.name} autoEscape />
        </strong>
        <div>
          {suggestion.addon && (
            <Label
              bgColor={selected ? colors.softGray : colors.softGray60}
              textColor={colors.textColor}
              className="mr1"
            >
              Tillägg
            </Label>
          )}
          {durationStr}
          {suggestion.price > 0 ? `, ${priceStr}` : null}
        </div>
      </div>
    );
  };

  renderToggle = (selected) => {
    return this.props.allowMultiple && (
      <div className={selected ? 'toggle selected' : 'toggle'}>
        <i className="fa fa-check" />
      </div>
    );
  };

  renderCreateItem = () => {
    return (
      <section>
        <i className="fa fa-fw fa-plus" /> <strong>{txt(msg.btnAddItem)}</strong>
      </section>
    );
  };

  renderSuggestion = (suggestion) => {
    const { context, selectedServices, allowMultiple } = this.props;
    const selected = selectedServices && selectedServices.has(suggestion.id);

    if ((context === 'FindTime' || !allowMultiple) && !suggestion.id) {
      return null;
    }

    return suggestion.id ? (
      <div className={selected ? 'booking-form-suggestion selected' : 'booking-form-suggestion'}>
        {this.renderService(suggestion, selected)}
        {this.renderToggle(selected)}
      </div>
    ) : (
      <div className="booking-form-suggestion new-item">
        {this.renderCreateItem()}
      </div>
    );
  };

  render() {
    const { context, height, maxHeight, disabled } = this.props;
    const { value, suggestions } = this.state;
    const iosWithOSKOpen = this.props.deviceOs === 'iOS' && !this.props.externalKeyboard && this.state.inputFocused !== false;

    const inputProps = {
      placeholder: context === 'FindTime' ? txt(msg.placeholderSearch) : txt(msg.placeholderAddItem),
      onKeyDown: this.handleKeyDown,
      onChange: this.onChange,
      value
    };

    const theme = {
      suggestionsList: 'booking-form-suggestions',
      suggestionHighlighted: 'highlighted',
      suggestionsContainer: iosWithOSKOpen ? 'booking-form-suggestions-container-ios' : 'booking-form-suggestions-container'
    };

    const styleBookingForm = { height, maxHeight: maxHeight - 50 };

    return (
      <div className="booking-form">
        <div className="booking-form-header">
          <div className="cancel">
            <a href="#" onClick={this.handleCancel}>
              <i className="fa fa-chevron-left" /> {txt(msg.btnBack)}
            </a>
          </div>
          <h4 className="title">
            {txt(msg.lblTitle)}
          </h4>
          <div className="toggle">
            <a href="#" onClick={this.handleClear}>
              {txt(msg.btnClear)}
            </a>
          </div>
        </div>

        <Bouncefix className={disabled ? 'booking-form-body disabled' : 'booking-form-body'} style={styleBookingForm}>
          <Autosuggest
            theme={theme}
            suggestions={suggestions}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            onSuggestionHighlighted={this.onSuggestionHighlighted}
            onSuggestionSelected={this.onSuggestionSelected}
            renderInputComponent={this.renderInputComponent}
            renderSuggestion={this.renderSuggestion}
            getSuggestionValue={() => value}
            alwaysRenderSuggestions
            shouldRenderSuggestions={() => true}
            focusInputOnSuggestionClick={false}
            inputProps={inputProps}
          />
        </Bouncefix>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { bkf, findTime, resourceServices } = state;
  const isBooking = ownProps.context === 'Booking';
  const resourceId = isBooking
    ? bkf.get('resourceId')
    : null;

  return {
    id: bkf.get('id'),
    service: bkf.get('service'),
    startTime: bkf.get('startTime'),
    endTime: bkf.get('endTime'),
    resourceId,
    resourceServices,
    services: isBooking
      ? (resourceServices.get(resourceId) || [])
      : getServicesForResourcesInView(state, ownProps),
    selectedServices: isBooking
      ? bkf.get('services')
      : findTime.get('services'),
    serviceColors: getServiceColors(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchResourceServices: (resId, clearCache) => {
      return dispatch(fetchResourceServices(resId, clearCache));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchService);
