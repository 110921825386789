import React, { useState } from 'react';
import PosOrderModal from '@Components/pos/pos-order-modal';

const PosGetStarted = () => {
  const [showModal, setShowModal] = useState(false);

  const onShowModal = (ev) => {
    ev.preventDefault();
    setShowModal(true);
  };

  const onReadMore = (ev) => {
    ev.preventDefault();
    document.getElementById('pos-first-feature').scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="pos">
      {showModal && <PosOrderModal onClose={() => setShowModal(false)} />}
      <div className="pos-get-started">
        <div className="pos-header">
          <div className="pos-left-part">
            <p className="text-news">Nyhet!</p>
            <h1>Cliento Kassa</h1>
            <p className="text-info lead">
              Ta betalt direkt från Cliento. Vi kopplar ihop din försäljning med dina kunder så att du har allt samlat på ett ställe.
            </p>
            <p className="text-info lead">
              <strong>från 199 kr/mån</strong>
            </p>
            <a href="#" onClick={onShowModal} className="btn btn-modern btn-xl">
              Beställ
            </a>
            <a href="#" onClick={onReadMore} className="btn btn-modern outline btn-xl">
              Läs mer
            </a>
          </div>
          <div className="pos-right-part">
            <img src="/pos/ipad-screenshot.png" className="pos-screenshot" />
            <h4 className="pos-platforms">
              <span><i className="fab fa-apple" />&nbsp;Mac</span>
              <span><i className="fab fa-windows" />&nbsp;Windows</span>
              <span><i className="fab fa-apple" />&nbsp;iOS</span>
              <span><i className="fab fa-android" />&nbsp;Android</span>
            </h4>
          </div>
        </div>

        <div className="pos-feature" id="pos-first-feature">
          <div className="pos-left-part">
            <h3>Se dina kunders köphistorik</h3>
            <p className="text-info">
              Försäljning i Cliento kassa kopplas automatiskt till kundkortet
            </p>
          </div>
          <div className="pos-right-part">
            <img src="/pos/sales-history.png" className="pos-image" />
          </div>
        </div>

        <div className="pos-feature">
          <div className="pos-left-part">
            <h3>Skicka digitala kvitton</h3>
            <p className="text-info">
              Slipp papperskvitton, mejla kvittot till dina kunder istället
            </p>
          </div>
          <div className="pos-right-part">
            <img src="/pos/send-receipt.png" className="pos-image" />
          </div>
        </div>

        <div className="pos-feature">
          <div className="pos-left-part">
            <h3>Integrerad kortterminal</h3>
            <p className="text-info">
              Med en integrerad kortterminal slipper du knappa in beloppet manuellt och allt samlas på kassakvittot. Dagsavslutet görs automatiskt när du stänger kassan.
            </p>
          </div>
          <div className="pos-right-part">
            <img src="/pos/lane-3000.png" className="pos-image" />
          </div>
        </div>

        <div className="pos-feature">
          <div className="pos-left-part">
            <h3>Sälj presentkort</h3>
            <p className="text-info">
              Sälj och lös in presentkort, vi tar hand om redovisningen
            </p>
          </div>
          <div className="pos-right-part">
            <img src="/pos/gift-card.png" className="pos-image" />
          </div>
        </div>

        <div className="pos-feature center">
          <div className="pos-left-part">
            <h3>Integrera med Fortnox</h3>
            <p className="text-info">
              Förenkla för din verksamhet med automatisk daglig bokföring
            </p>
          </div>
          <div className="pos-right-part">
            <img src="/pos/fortnox.png" className="pos-image" />
          </div>
        </div>

        <div className="pos-feature">
          <div className="pos-left-part">
            <h3>Fler funktioner</h3>
            <p className="text-info">
              - Flera företag i samma kassa<br />
              - Molnbaserad kontrollenhet<br />
              - Lagerhantering<br />
              - Rapporter och statistik<br />
              - Digitala Z-dagrapporter och dagsavslut
            </p>
          </div>
          <div className="pos-right-part">
            <img src="/pos.svg" className="pos-image" />
          </div>
        </div>

        <div className="text-center">
          <a href="#" onClick={onShowModal} className="btn btn-modern cta btn-xl">
            Beställ eller kontakta oss
          </a>
        </div>
      </div>
    </div>
  );
};

export default PosGetStarted;
