import 'core-js/stable';
import 'regenerator-runtime/runtime';
import '@Utils/outdated-browser';
import 'whatwg-fetch';
import 'underscore';

import Immutable from 'immutable';
import React from 'react';
import ReactDOM from 'react-dom';
import UAParser from 'ua-parser-js';
import { Provider } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';

import '../vendor/styles/vendor.less';
import './styles/index.less';

import { createWebkitBridges } from '@Utils/wk-embed-bridges';
import { calculateScrollbarWidth } from '@Components/calendar/grid/grid-util';
import {
  getEmbedSettings,
  getGridSize,
  isExternalKeyboardEnabled,
  isHighContrastEnabled,
  isShowChipTooltipEnabled,
  updateEmbedSettings
} from '@Utils/local-settings';
import { getResourceIdsFromSearch } from '@Utils/url-util';
import { getViewDimensions } from '@Utils/dimensions';
import { getHistory } from '@Utils/navigate';
import Login from '@Login/login-form';
import configureStore from './configureStore';
import LocationConfigEnforcer from './location-config-enforcer';

updateEmbedSettings(); // This will set the embed mode in localStorage, or clear it if 'none' is provided

const load = () => {
  const initialState = createInitialState();
  const store = configureStore(initialState);
  createWebkitBridges(store, initialState);

  ReactDOM.render(
    <Provider store={store}>
      <Router history={getHistory()}>
        <Switch>
          <Route path="/login-dev" component={Login} />
          <Route path="/:org?/:loc?/" component={LocationConfigEnforcer} />
        </Switch>
      </Router>
    </Provider>,
    document.getElementById('react-root')
  );
};

function createInitialState() {
  const sbw = calculateScrollbarWidth();
  const scrollBars = sbw > 0;
  const scrollBarWidth = sbw;
  const externalKeyboard = isExternalKeyboardEnabled();
  const highContrast = isHighContrastEnabled();
  const showChipTooltip = isShowChipTooltipEnabled();
  const gridSize = getGridSize();
  const parser = new UAParser();
  const viewDimensions = getViewDimensions();
  const deviceType = parser.getDevice().type || 'desktop';
  const deviceOs = parser.getOS().name;
  const embedSettings = getEmbedSettings();
  const searchResourceIds = getResourceIdsFromSearch(window.location.search);
  const multiResourceMode = searchResourceIds.length > 0;

  return {
    appState: Immutable.Map({
      ...embedSettings
    }),
    calendarViewState: Immutable.Map({
      lastView: null,
      viewDate: null,
      loadingViewData: false,
      resourceListCollapsedIds: [],
      multiResourceMode
    }),
    gridViewState: Immutable.Map({
      pixelsPerRow: 15,
      rowsPerHour: 6,
      gridMarkerDuration: 60,
      gridClientWidth: 500,
      gridClientHeight: 500,
      highContrast,
      showChipTooltip,
      externalKeyboard,
      largeCalendar: false,
      showGridMarker: false,
      clipBoardDragger: null,
      scheduleEditMode: false,
      undoableBooking: null,
      gridSize,
      gridScrollable: true, // if set to true, prevent scrolling of the grid view, when popups are open etc..
      scrollBars,
      scrollBarWidth
    }),
    mainViewState: Immutable.Map({
      ...viewDimensions,
      deviceType,
      deviceOs
    })
  };
}

if (document.readyState !== 'complete') {
  document.addEventListener('DOMContentLoaded', load);
} else {
  load();
}
