import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

const BookingTypeItem = ({ currentType, type, setType, children }) => (
  <li className={currentType === type ? 'is-active' : ''}>
    <a href="#" onClick={ev => setType(ev, type)}>{children}</a>
  </li>
);

const BookingType = ({ currentType, onTypeChange }) => {
  const enableClassBooking = useSelector(state => state.locationFeatures.get('EnableClassBooking'));

  const setType = useCallback((ev, typeString) => {
    ev.target.blur();
    ev.preventDefault();
    onTypeChange(typeString);
  }, []);

  return (
    <div className="booking-form-segment">
      <ul>
        <BookingTypeItem currentType={currentType} setType={setType} type="SimpleBooking">
          Bokning
        </BookingTypeItem>
        {enableClassBooking && (
          <BookingTypeItem currentType={currentType} setType={setType} type="ClassBooking">
            Grupp/klass
            <span className="label-news">NY</span>
          </BookingTypeItem>
        )}
        <BookingTypeItem currentType={currentType} setType={setType} type="Reservation">
          Blockera tid
        </BookingTypeItem>
      </ul>
    </div>
  );
};

export default BookingType;
