import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createPosOrg, fetchPosOrgs } from '@State/pos-config-actions';
import { getPosOrgUrl, navigate } from '@Utils/navigate';
import CreatePosOrgDialog from '@Components/admin/pos-config/create-pos-org-dialog';
import Loader from '@Components/ui/loader';

const PosOrgEmpty = ({ match }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const posOrgsLoaded = useSelector(state => state.adminViewState.get('posOrgsLoaded'));

  useEffect(() => {
    if (!posOrgsLoaded) {
      setLoading(true);
      dispatch(fetchPosOrgs())
        .finally(() => setLoading(false));
    }
  }, []);

  const onCreatePosOrg = (data) => {
    return dispatch(createPosOrg(data))
      .then(({ id }) => navigate(getPosOrgUrl(match.params, id)))
      .then(() => setShowDialog(false));
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <div className="subnav-container center">
        <div>
          <h3>Kom igång med Cliento Kassaregister</h3>
          <img src="/pos.svg" width="300" />
          <p className="lead">
            Cliento Kassaregister är helt molnbaserat, har stöd för flera företag<br />
            och erbjuder varulager, kort- och kontantbetalningar samt Swish.
          </p>
          <br />
          <button onClick={() => setShowDialog(true)} className="btn btn-modern btn-xl">
            Skapa nytt kassaregister <i className="fa fa-chevron-right" style={{ marginLeft: 10 }} />
          </button>
        </div>
      </div>
      {showDialog && <CreatePosOrgDialog onHideDialogs={() => setShowDialog(false)} onCreatePosOrg={onCreatePosOrg} />}
    </>
  );
};

export default PosOrgEmpty;
